import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import Common from "@/core/services/common";
import RegionJs from "@/core/services/store/modules/region.js";
import CoreJs from "@/core/services/core.js";
import MasterDataJs from "@/core/services/store/modules/master_data.js";
import CoreSettingsJs from "@shared/src/core/services/settings.js";
import Antd from "ant-design-vue";
import SelectField from "@shared/src/components/SelectionField.vue";
import DateRangePicker from "@shared/src/components/dateRangePicker.vue";
import StandardSearchForm from "@/view/content/WmsStandardSearchForm.vue";
import WmsSummary from "@/view/layout/form/WmsSummary.vue";
import WmsMasterDataSummary from "@/view/content/WmsServerMasterDataSummary.vue";
import WmsSummaryRowAction from "@/view/content/WmsSummaryRowAction.vue";
import FormAlert from "@/view/content/FormAlert";
import WmsMasterForm from "@/view/layout/form/WmsMasterForm.vue";
import CreationModified from "@/view/content/form/CreationModified.vue";
import ButtonForm from "@/view/content/form/formButton.vue";
import FormSection from "@/view/content/WmsFormSection.vue";


import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { GET_SYS_SETTINGS, GET_LOCALE } from "@/core/services/store/syssettings.module";
import VModal from "vue-js-modal";
import Swal from "sweetalert2";
import JsonViewer from 'vue-json-viewer'


Vue.prototype.$enviroment = process.env.NODE_ENV;
Vue.prototype.$ApiService = ApiService;
Vue.prototype.$Common = Common;
Vue.prototype.$RegionJs = RegionJs;
Vue.prototype.$Swal = Swal;
Vue.prototype.$CoreJs = CoreJs;
Vue.prototype.$MasterDataJs = MasterDataJs;
Vue.prototype.$CoreSettingsJs = CoreSettingsJs;


Vue.config.productionTip = false;

import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
Vue.component("selectField", SelectField);
Vue.component("dateRangePicker", DateRangePicker);
Vue.component("standardSearchForm", StandardSearchForm);
Vue.component("wmsSummary", WmsSummary);
Vue.component("wmsMasterDataSummary", WmsMasterDataSummary);
Vue.component("wmsSummaryRowAction", WmsSummaryRowAction);

Vue.component("buttonForm", ButtonForm);
Vue.component("creationModified", CreationModified);
Vue.component("wmsMasterForm", WmsMasterForm);
Vue.component("formAlert", FormAlert);
Vue.component("formSection", FormSection)

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "ant-design-vue/dist/antd.css";
import "@shared/src/assets/css/gbizsUtilities.css";

Vue.use(Antd);
Vue.use(JsonViewer);
Vue.use(VModal);

//Vue global directive
Vue.directive("strToUppercase", {
	update: function (el) {
		el.value = el.value.toUpperCase();
	}
});

//条形码
import JsBarcode from "@shared/src/components/JsBarcode";
Vue.use(JsBarcode);

Vue.filter("uppercase", function (value) {
	return value.toUpperCase();
});

// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
	document.title = to.meta.title || "GIM WMS";
	let middleware = [store.dispatch(VERIFY_AUTH), store.dispatch(GET_SYS_SETTINGS)];
	if (store.getters.isAuthenticated) {
		middleware.push(store.dispatch(GET_LOCALE));
	}
	// Ensure we checked auth before each page load.
	Promise.all(middleware)
		.then(next)
		.catch(() => router.push({ name: "login" }));

	// reset config to initial state
	store.dispatch(RESET_LAYOUT_CONFIG);

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});

new Vue({
	router,
	store,
	i18n,
	vuetify,
	render: (h) => h(App)
}).$mount("#app");
