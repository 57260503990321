// USA
export const locale = {
	MENU: {
		DASHBOARD: "主页",
		ASIDE: {
			CUSTOMER_MANAGEMENT: "客户管理",
			USER: "用户管理",
			ROLE: "角色管理",
			DATA_FILTER: "过滤数据管理",
			USER_N_ROLE_MANAGEMENT: "用户及角色管理",
			MASTER_DATA: "基础管理",
			TRANSACTION: "交易",
			CUSTOMER: "客户",
			DASHBOARD: "主页",
			COUNTRIES_REGION: "国家及地区",
			COUNTRIES: "国家",
			STATES: "州",
			CITIES: "城市",
			DISTRICT: "区",
			POSTCODES: "邮政编码",
			CURRENCIES_TAX: "货币与税收",
			CURRENCIES: "货币",
			COMPANY: "企业",
			WAREHOUSE: "仓库",
			SALESMAN_MANAGEMENT: "Salesman管理",
			MAINSALESMAN: "Main Salesman",
			MAINTAIN: "快递管理",
			MAINTAIN_AGENT: "快递代理",
			MAINTAIN_COURIER_SERVICE: "快递服务",
			SHIPMENT: "运输",
			SHIPMENT_TYPE: "运输类型",
			SHIPMENT_MODE: "运输方式",
			MOVEMENT_TYPE: "Movement Type",
			SHIPMENT_TERMS: "装运条款",
			REFERENCE_TYPE: "Reference Type",
			CATEGORY_GROUP: "Category Type",
			CATEGORY_PARENT: "产品类别（父级）",
			CATEGORY: "产品类别（2 级）",
			FORWARDER_GROUP: "货代类型",
			SHIPPER: "托运人",
			COURIER_COMPANY: "快递公司",
			CONSIGNEE: "Consignee",
			CONSIGNEE_GROUP: "Consignee Group",
			SHIPPING_LINE: "Shipping Line",
			SALESMAN: "Salesman",
			SETTINGS: "设置管理",
			GENERAL_SETTINGS: "基础设置",
			NOTICE_TYPE_GROUP: "Notice Type Group",
			NOTICE_TYPE: "Notice Type",
			DOC_TYPE: "单号类型",
			DOC_NUM: "单号格式",

			PORT: "港口",
			UOM: "Stock UOM",
			FORWARDER: "货代",

			WAREHOUSE_LOCATION: "货物位置",
			DELIVERY_ADDRESS: "收货地址",

			LANGUAGE: "语言",

			SUPPLIER: "厂家",
			ORDER: "订单",
			INBOUND: "收货咨询",
			ORDER_RECEIVING: "接收订单",
			LOAD_CONTAINER: "Ref Id & 装柜",
			CONTAINER_DETAILS: "Ref Id 详情",
			CONTAINER_LOADING: "装柜",
			CONTAINER_CLOSE: "关柜",
			CONTAINER_OPEN: "重开柜",
			CONTAINER_REOPEN_SO: "重开订单更新",
			CLOSE_CONTAINER_REOPEN_SO: "关闭已开订单",
			EPO_LISTING: "EPO列表",
			PAYABLES: "欠单",
			PAYABLES_REPORTING: "欠单报表",
			PAYABLE_SETTING: "设置欠单",
			PAYABLES_PAYMENT: "欠单付款",
			SYSTEM_LOG: "系统日志",
			BACKEND_API_LOG: "后台Api日志",
			GIM_API_LOG_TITLE: "GBZIS Api日志",
			PRINT: "打印报表",
			GIMWORLD_ORDER: "Gimworld订单",
			GIMWORLD_ORDER_MAINTENANCE: "Gimworld订单管理",
			POST_ORDER: "提交到接收订单",
			PACKING_LIST: "Packing List",
			PAYMENT_INFO: "Stock Balance Report with Payment Info",
			MANIFEST_CONSIGNMENT_MANAGEMENT: "Manifest Consignment Management",
			DELIVERY_AGENT_ASSIGNMENT: "Delivery Agent Assignment",
			CONSIGNMENT: "Consignment",
			WAREHOUSE_SETTINGS: "仓库设置",
			CONSIGNMENT_AGENT_COURIER: "Consignment Agent Courier",
			CONTAINER: "集装箱",
			CONTAINER_COURIER_SERVICE: "快递公司",
			CONTAINER_DELIVERY_AGENT: "送货代理"
		}
	},
	AUTH: {
		GENERAL: {
			OR: "Or",
			SUBMIT_BUTTON: "Submit",
			NO_ACCOUNT: "Don't have an account?",
			SIGNUP_BUTTON: "Sign Up",
			FORGOT_BUTTON: "Forgot Password",
			BACK_BUTTON: "Back",
			PRIVACY: "Privacy",
			LEGAL: "Legal",
			CONTACT: "Contact"
		},
		LOGIN: {
			TITLE: "登录WMS系统",
			BUTTON: "登录"
		},
		FORGOT: {
			TITLE: "忘记密码？",
			DESC: "输入您的邮箱以重置密码。",
			SUCCESS: "您的帐户已成功重置。"
		},
		INPUT: {
			EMAIL: "邮箱",
			FULLNAME: "全名",
			PASSWORD: "密码",
			CONFIRM_PASSWORD: "确定密码",
			USERNAME: "账号名"
		},
		RESPONSE_MSG: {
			LOGIN: {
				200: "账号成功登录",
				2001: "账号不存在"
			}
		}
	},
	COMMON: {
		DATE: "日期",
		FROM: "日期从",
		TO: "日期至",
		BTN_GENERATE: "创建",
		BTN_BROWSE: "浏览",
		BTN_SEARCH: "搜索",
		BTN_CANCEL: "取消",
		BTN_BACK: "返回",
		BTN_SAVE: "保存",
		BTN_EDIT: "编辑",
		BTN_CREATE: "创建{btn_name}",
		BTN_SUBMIT: "提交",
		BTN_ADD: "添加",
		BTN_DELETE: "删除",
		BTN_COPY: "复制",
		BTN_OPEN: "打开",
		BTN_CLOSE: "关闭",
		BTN_CONFIRM: "确认",
		BTN_APPORVE: "批准",
		BTN_PREVIEW: "预览",
		BTN_SAVE_AS_DRAFT: "另存为草稿",
		BTN_DOWNLOAD_AS_PDF: "另存为PDF",
		BTN_PRINT: "打印",
		BTN_CONFIRM_N_APPROVE: "确认并批准",
		BTN_VIEW: "查看",
		BTN_RESET: "重置",
		BTN_DOWNLOAD_ALL: "全部下载",
		BTN_DOWNLOAD_SELECTED: "下载所选内容",
		BTN_SIGN_OUT: "登出",
		BTN_UPLOAD: "上传",
		BTN_REMOVE_FAVOURITE: "从收藏夹中删除",
		BTN_FAVOURITE: "标记为收藏夹",
		BTN_NO: "不是的",
		BTN_YES: "是的",
		BTN_OK: "好的",

		LBL_CREATED_BY: "创建人",
		LBL_CREATED_AT: "创建于",
		LBL_UPDATED_BY: "修改人",
		LBL_UPDATED_AT: "修改于",
		LBL_STATUS: "状态",
		LBL_COUNTRY: "国家",
		LBL_STATES: "州/省",
		LBL_CITY: "城市",
		LBL_POSTCODE: "邮政编码",
		LBL_ADDRESS: "地址",

		LBL_CHECKER: "点货员",
		LBL_USE_FOR_RECEIVING: "用于EPO/收货",
		LBL_WAREHOUSE: "仓库",
		LBL_NOTICE_TYPE: "Notice Type",
		LBL_DELIVERY_ID: "Delivery Id",
		LBL_CUSTOMER_NAME: "客户名",
		LBL_MARKING: "客户代号",
		LBL_FULL_MARKING: "Full 客户代号",
		LBL_TOTAL_CARTON: "箱",
		LBL_TOTAL_PALLET: "托盘",
		LBL_M3: "M3",
		LBL_WEIGHT: "重量(Kg)",
		LBL_SO_NO: "S/O号",
		LBL_CURRENCY: "货币",
		LBL_RECEIVE_DATE: "收货日期",
		LBL_TOTAL: "总额",
		LBL_REMARK: "备注",
		LBL_ACTION: "功能",
		LBL_DELIVERY_ZONE: "送货区域",
		LBL_REF_ID: "Ref Id",
		LBL_LOADED_DATE: "上柜日期",
		LBL_SUPPLIER: "厂家",
		LBL_SALESMAN: "Salesman",
		LBL_MAINSALESMAN: "Main Salesman",
		LBL_COMPANY_ID: "CO. ID",
		LBL_RECEIVED_AMOUNT: "订单金额",

		PH_ENTER_CREATED_BY: "输入创建人",
		PH_ENTER_CREATED_AT: "输入创建于",
		PH_ENTER_UPDATED_BY: "输入修改人",
		PH_ENTER_UPDATED_AT: "输入修改于",

		PH_AUTO_DOC_NO: "自动生成",

		MSG_FAILED_CONNECT_SERVER: "连接服务器失败。请联系系统管理员",

		COL_STATUS: "状态",
		COL_AGENT: "GBIZS代理",
		COL_MODIFIEDBY: "修改人",
		COL_MODIFIEDBYDATE: "修改于",
		COL_ACTION: "功能",
		COL_MODIFIED_ON: "修改于",
		COL_CHECKER: "点货员",
		COL_CREATED_AT: "创建于",
		COL_CREATION_BY: "创建人",
		COL_EMAIL: "邮箱",
		COL_FULL_MARKING: "Full 客户代号",
		COL_RECEIVED_DATE: "收货日期",
		COL_SO_NO: "S/O号",

		TXT_ALL: "全部",
		TXT_NEW: "新",
		TXT_ERROR: "错误",
		TEXT_WARNING: "警告",

		MSG_CONFIRM_DELETE: "确定要删除吗？",
		DELETE_MODAL_TITLE: "确认删除记录？",
		OPERATION_MSG: "你确定吗？",
		SELECTION_OPTION_TITLE: "-- 选择选项 --",
		SELECTION_NONE: " -- None --",
		CATEGORY_ROOT_SELECTION: " --- 类别1级 ---",
		CATEGORYT_SELECTION: "--- 类别二级 ---",
		MSG_FAILED_TO_UPDATE: "{mod} 已编辑失败",
		MSG_SUCCESS_TO_UPDATE: "{mod} 已成功编辑",

		MSG_FAILED_CREATE: "{mod} 已创建失败",
		MSG_SUCCESS_TO_CREATE: "{mod} 已成功创建",
		MSG_FAILED_DELETE: "{mod} 删除失败",
		MSG_DATA_EXIST: "提醒：此记录已存在",
		MSG_DATA_EXIST_SECOND_LINE: "系统不允许同一数据记录保存两次。",
		FAILD_UPLOAD_FILE: "哎呀。。。，无法将文件上载到服务器。请重试",

		MSG_SUCCESS_TO_DELETE: "{mod}已录删除成功",
		PH_DROP_FILE: "选择一个文件或将其放到此处。。。",
		DROP_PH_DROP_FILE: "将文件放到此处。。。",
		MSG_INVALID_EMAIL: "邮箱无效",
		MSG_NOT_ALLOW_DELETE: "哎呀，不允许删除此｛mod｝记录",
		LBL_COUNTRIES: "国家",
		LBL_STATES: "州",
		LBL_CITY: "市",
		LBL_POSTCODE: "邮编",
		LBL_SHIPMENT_TYPE: "运输类型",
		LBL_SHIPMENT_MODE: "运输方式",

		SELECT_ALL_TITLE: "选择全部",

		MSG: {
			MSG_ERROR_TITLE: "哎呀。。。",
			MISSING_DOC_TYPE: "单号类型为设置。",
			NOT_FOUND: "{name} 未找到",
			API_SERVICE_ERROR: "系统错误，请协助系统管理员",
			SUCCESSFUL_SAVE: "成功保存记录",
			ARE_YOU_SURE: "你确定？",
			MSG_UNABLE_UPLOAD_IMAGE: "无法上传图像。请重试"
		},

		VALIDATION: {
			INVALID: "{name} 无效",
			REQUIRED: "{name} 是必需的",
			MIN_LENGTH: "{name} 最小长度为 {min}",
			NOT_FOUND: "找不到 {name} 请求",
			INVALID_LOGIN: "登录详细信息不正确",
			MIN_LENGTH_FIELD: "Minimum field length:",
			MAX_LENGTH_FIELD: "Maximum field length:",
			INVALID_FIELD: "Field is not valid",
			NUMBER: "{name} 必须是数字",

			INVALID_WARNING_MSG: "Please, provide correct data!",
			DUPLICATE_ENTRY: "{name} is duplicate. Please Try Again",
			UNIQUE_FIELD: "{name} must be unique.",
			DOCUMENT_NUMBERING_NOT_SET: "Document Number Format is not found. Please setup first.",
			ID_NOT_FOUND: "Record not found",
			GREATER_THAN_ZERO: "{name} must be greater than 0"
		},

		SEARCHFORM: {
			LBL_MARKING: "客户代号",
			LBL_MAIN_SALESMAN: "Main Salesman",
			LBL_SALESMAN: "Salesman",
			LBL_AGENT: "GBizs代理",
			LBL_WAREHOUSE: "仓库",
			LBL_SHIPMENT_TYPE: "运输类型",
			LBL_CUSTOMER_NAME: "客户名",
			LBL_DELIVERY_ID: "Delivery Id",
			LBL_COMPANY: "Company",
			LBL_COMPANY_GROUP: "Company Group"
		},
		NEW_TITLE: "New",
		SUMMARY_COL: {
			COL_MSM_CODE: "Main Salesman",
			COL_SM_CODE: "Salesman",
			COL_COMPANY_GRP: "Company Grp",
			COL_MARKING: "客户代号",
			COL_FULL_MARKING: "Full 客户代号",
			COL_DELIVERY_ID: "Delivery Id",
			COL_CUSTOMER_NAME: "客户名",
			COL_AGENT: "GBizs代理"
		},
		ADDRESS: {
			LBL_ADDRESS: "地址",
			LBL_UPSTAIRS: "上楼"
		}
	},
	ORDER: {
		INBOUND: {
			MODULE_NAME: "收货咨询",
			LISTING_TITLE: "收货咨询列表",
			CREATE_TITLE: "创建收货咨询",
			DETAIL_TITLE: "收货咨询详情",
			EDIT_TITLE: "编辑收货咨询详情",
			PRINT_GRN_RECEIPT: "打印托运单收据",
			PRINT_CTN_LABEL: "打印纸箱贴纸",
			PRINT_GRN: "打印托运单",
			PRINT_PO_LABEL: "打印PO贴纸",
			REMARK: "备注",
			CHECKER: "点货员",
			SUPPLIER_LIST: "供应商",
			DRIVER_LIST: "送货司机",
			SUPPLIER_DETAILS: "供应商详情",
			SUPPLIER: {
				DETAILS: "供应商详情",
				CONTACT: "供应商联络号",
				DRIVER_NAME: "司机名",
				DRIVER_CONTACT: "司机联络号",
				SUPPLIER_NAME: "供应商名",
				SUPPLIER_CONTACT: "供应商联络号",
				BANK_ACC_NAME: "银行账户名称",
				BANK_ACC_NO: "银行账号"
			},
			MSG: {
				SUPPLIER_NOT_FOUND: "供应商不存在",
				DRIVER_NOT_FOUND: "司机不存在。",
				CONFIRM_SAVE_SUPPLIER: "您确定要将此另存为新供应商吗: {supplier}",
				CONFIRM_SAVE_DRIVER: "您确定要将此保存为供应商的新驱动程序详细信息吗: {supplier}",
				EPO_NOT_FOUND: "EPO未找到，无法接收",
				SPECIAL_INS_FAILED: "未获得指示。",
				PLT_CTN_EMPTY: "托盘/纸箱数不能为空",
				RECEIVED_AMT_EMPTY: "收货金额不能为空"
			},
			SAVE_NEW_DRIVER: "另存为新司机",
			SAVE_NEW_SUPPLIER: "另存为新供应商",
			CONFIRMATION_CONTINUE_RECEIVE: "Epo:{epo_no}请求停止接收<br/>您确定要继续接收吗？",
			CONFIRMATION_DUPLICATE_SO_NO: "收货咨询已存在。<br />您确定要继续接收吗?",
			SUPPLIER_EXISTS: "供应商已存在：｛supplier_name｝。请使用其他供应商名称。",
			DRIVER_EXISTS: "司机程序已存在：｛supplier_name｝。请使用其他驱动程序名称。",
			BLANK_SO_NO: "无单号"
		},
		ORDER_RECEIVING: {
			MENT_NAME: "接收订单",
			LISTING_TITLE: "接收订单列表",
			CREATE_TITLE: "新建接收订单",
			DETAIL_TITLE: "接收订单详情",
			EDIT_TITLE: "编辑接收订单",
			RECEIVED_DATE: "收货日期",
			DELIVERY_ZONE: "送货区域",
			PH_ENTER_INQUIRY_DATE_FROM: "输入收货日期从",
			PH_ENTER_INQUIRY_DATE_TO: "输入收货日期至",
			CATEGORY_TYPE: "品类类型",
			DROPAT: "库位",
			RECEIVED_AMT: "接受金额",
			CASH_PAYMENT: "现金支付",
			MY_PAID_AMOUNT: "支付金额 (MY)",
			PAYMENT_ON_AMOUNT: "按金额付款",
			BAL_AMT: "余额",
			PAYABLE_NOTE_NO: "欠单/托运单",
			TTL_ITEM: "货物总数",
			DEPOSIT: "订金",
			CTN: "箱",
			CTN_PER_PLT: "每托盘箱数",
			PLT: "托盘.",
			KG: "重量(KG)",
			M3: "M3",
			CUSTOMER_M3: "CM3",
			CREDIT_TERM: "信用期限",
			IS_SENSITIVE: "敏感货?",
			ORDER_RECEIVING_DETAILS_CANNOT_BE_EMPTY: "接收订单详情详情不能为空",
			BALANCE_AMOUNT_CANNOT_LESS_THAN_ZERO: "FCP/PN 金额不能小于零.",

			VERSION: "版本",
			SENSITIVE: "敏感",
			PARCEL_LOST_GUARANTEE: "货物保障",
			DUE_DAYS: "截止天数",
			SO_CUS_DEPOSIT: "S/O客户订金",
			SO_SUP_DEPOSIT: "S/O厂家订金",
			TOTAL_DIMENSION: "总尺寸",
			DIMENSION: "尺寸",
			DCP_AMOUNT: "DCP 金额",
			INSTRUCTION_BY_MARKING: "客户指示",
			INSTRUCTION_BY_SO: "订单指示",
			REMARK2: "备注（2）",
			MY_PAID: "My Paid",
			OTHER_AMOUNT: "Sup WH Disc",
			FCP_AMOUNT: "FCP Amount",
			DISCOUNT_AMOUNT: "Discount Amount",
			BALANCE_AMOUNT: "Balance Amount",
			PN_AMT: "PN Amt",
			STICKER_FEES_PER_CARTON: "Sticker Fees / Ctn",
			STICKER_FEES: "Sticker Fees",
			PAID_BY_SUP: "Paid By Sup?",
			SUPPLIER_PAY_AMOUNT: "Supplier pay Amount",
			CTN_NO: "CTN No.",
			CONFIRM_SAVE_BENE_MASTER: "Are you sure want to save these beneficiary details as new master record?",
			BENE_MASTER_SAVED: "New beneficiary info created successfully",
			PRINT_GRN: "打印 GRN",
			PRINT_PN: "打印 Payable Notes",
			PRINT_PO_LABEL: "打印 PO Label",
			PRINT_CTN_LABEL: "打印 Ctn Label",
			EPO_LIST: "Epo Listing",
			ACTION: "Action",
			REMARKS: "Remarks",
			EPO_DATE: "EPO Date",
			EPO_AMOUNT: "EPO Amount",
			CAN_RECEIVE_AMOUNT: "Can Receive Amount",
			MSG: {
				EPO_NOT_FOUND: "EPO : {epo} Not Found, please make sure you enter the EPO correctly",
				EPO_NOT_INBOUND: "EPO : {epo} not yet Status Inquiry, please contact Status Inquiry PIC",
				NO_NEW_INBOUND: "EPO : {epo} existing Status Inquiry has been received, please contact Status Inquiry PIC",
				FULLY_RECEIVED: "EPO : {epo} has been fully received.",
				SO_RECEIVED: "This EPO has been partially received.",
				CANNOT_SAVE_BENE_MASTER: "Supplier not exist in master, bank info will not be able to save as master data.",
				CTN_M3_MUST_HAVE_VALUE: "Ctn & M3 must be greater than 0",
				AIR_SHIPMENT_TYPE_WEIGHT: "Air Shippment Kg must be greater than 0",
				CONFIRMATION_DIMENSION_NOT_TATLLY: "Total Dimension not equally total M3. Are you sure to continue?",
				BENE_EMPTY_DETAIL: "Bene details is empty. Are you sure to continue?",
				AVAIBLE_RECEIVE_QTY: "Available quantity smaller than received quantity. Are you sure to continue?"
			},

			DETAILS: {
				ADD: "Add",
				STOCK_ID: "Stock ID",
				PACKING_T: "Packing T",
				ITEM_CODE: "Item Code",
				INVOICE_DESC: "Invoice Desc",
				STOCK_DESC_1: "Stock Desc 1",
				STOCK_DESC_2: "Stock Desc 2",
				UOM: "UOM",
				RECEIVE_QTY: "Receive Qty",
				BUYING_PRICE: "Buying Price",
				RECEIVED_AMT: "Received Amt",
				OTHER_AMT: "WH Chgr Amt",
				DISCOUNT: "Discount",
				DEPOSIT: "Deposit",
				SUPPLIER_DEPOSIT: "Supplier Deposit",
				VOUCHER: "Voucher",
				REF_ID: "Ref ID",
				LOAD_Q: "Load Q",
				CLOSE: "Close",
				INV: "Inv",
				DO: "D/O",
				STK_MAST: "Stk Mast",
				VERSION: "Version",
				WAREHOUSE: "Warehouse",
				BLANK_SO_NO: "无单号",
				CPO_NO: "CPO No"
			},
			DIMENSION_LIST: {
				ADD: "Add",
				STICKER_FROM: "Sticker From",
				STICKER_TO: "Sticker To",
				LENGTH: "Length (cm)",
				WIDTH: "Width (cm)",
				HEIGHT: "Height (cm)",
				CTN_PER_PLT: "Ctn / pallet",
				PLT: "Pallet",
				CTN: "Ctn",
				KG: "Kg",
				M3: "M3",
				REMARK: "Remark",
				KG_NOT_TALLY: "Dimension List KG is not tally",
				CTN_NOT_TALLY: "Dimension List CTN is not tally",
				M3_NOT_TALLY: "Dimension List M3 is not tally"
			},

			PREVIEW_PN: "Preview Payable Note {pn_no}"
		},
		CONSIGNMENT: {
			DELIVERY_AGENT_ASSIGNMENT: {
				MODULE_NAME: "Delivery Agent Assignment",
				LISTING_TITLE: "Delivery Agent Assignment Listing",
				REF_ID: "Ref ID",
				CREATED_DATE: "Created Date",
				CREATED_DATE_FROM: "Created 日期从",
				CREATED_DATE_TO: "Created 日期至",
				CONTAINER_COURIER_SERVICE: "Courier Service",
				CONTAINER_DELIVERY_AGENT: "Delivery Agent"
			},
			MANIFEST_CONSIGNMENT: {
				MENT_NAME: "Manifest Consignment Management",
				LISTING_TITLE: "Manifest Consignment Management Listing",
				CREATE_TITLE: "Create New Manifest Consignment",
				DETAIL_TITLE: "Manifest Consignment Detail",
				EDIT_TITLE: "Edit Manifest Consignment",
				REF_ID: "Ref ID",
				PLEASE_SELECT_DATA: "Please select data",
				SO_NO: "SO NO",
				MARKING: "客户代号",
				DELIVERY_ID: "Delivery ID",
				SALESMAN: "Salesman",
				MAIN_SALESMAN: "Main Salesman",
				SEND_DATE: "Send Date",
				DOC_DATE: "Doc Date",
				DATE_FROM: "输入 Send 日期从",
				DATE_TO: "输入 Send 日期从",
				RECEIVED_AMT: "Received Amt",
				RECEIVED_DATE: "收货日期",
				RECEIVED_QTY: "Received Qty",
				CTN: "Ctn",
				TTL_CTN: "TTL CTN",
				TTL_W: "TTL W",
				TTL_M: "TTL M",
				VOLUME_WEIGHT: "Volume Weight",
				KG: "Kg",
				M3: "M3",
				REMARK: "Remark",
				DIMENSION: "Dimension",
				OVER_LENGTH: "Over Length",
				IS_SENSITIVE: "Is Sensitive ?",
				DESCRIPTION: "Description 1",
				DESCRIPTION2: "Description 2",
				CONSIGNMENT_NO: "Consignment NO",
				CONSIGNMENT_TYPE: "Consignment Type",
				CONSIGNMENT_NAME: "Consignment Name",
				CONSIGNMENT_ADDRESS: "Consignment Address",
				CONSIGNMENT_CONTACT: "Consignment Contact",
				CONSIGNMENT_TEL_NO: "Consignment Tel No.",
				GOODS_DESCRIPTION: "Goods Description",
				COURIER_SERVICE: "Courier Service",
				DELIVERY_COURIER_LABEL: "Delivery Agent Courier Service Label",
				DELIVERY_AGENT_NOT_ASSIGN: "Please make sure delivery agent has been assign to ref id."
			},
			CONSIGNMENT_AGENT_COURIER: {
				MENT_NAME: "Consignment Agent Courier",
				LISTING_TITLE: "Consignment Agent Courier Listing",
				CREATE_TITLE: "Create New Consignment Agent Courier",
				DETAIL_TITLE: "Consignment Agent Courier Detail",
				EDIT_TITLE: "Edit Consignment Agent Courier",
				REF_ID: "Ref ID",
				AGENT_ID: "Agent ID",
				COURIER_ID: "Courier ID",
				ORDER_DATE: "Order Date",
				COLLECT_DATE: "Collect Date",
				SHIPMENT_MODE: "Shipment Mode"
			}
		}
	},

	EPO_MANAGEMENT: {
		EPO_FORMAT: {
			EPO_FORMAT_PRINT_TITLE: "打印EPO"
		},
		EPO_LISTING: {
			LISTING_TITLE: "EPO列表",
			CREATE_TITLE: "创建EPO",
			DETAIL_TITLE: "EPO详情",
			EDIT_TITLE: "编辑EPO详情",

			MODULE_NAME: "EPO",

			EPO_MODULE_NAME: "EPO",

			LBL_EPO_DATE: "EPO日期",
			LBL_DELIVERY_ID: "Delivery ID",

			PH_ENTER_EPO_DATE_FROM: "输入 EPO 日期从",
			PH_ENTER_EPO_DATE_TO: "输入 EPO 日期至",
			COL_REQUEST_PLG: "货物保障",

			COL_EPO_NO: "EPO单号",
			COL_MARKING: "客户代号",
			COL_DELIVERY_ID: "Delivery Id",
			COL_COMPANY: "商号",
			COL_SHIPMENT_TYPE: "运输类型",
			COL_SHIPMENT_MODE: "运输方式",
			COL_WAREHOUSE: "仓库",
			COL_EPO_DATE: "EPO日期",
			COL_STATUS: "状态",
			COL_SUPPLIER_NAME: "供应商名",
			COL_GRAND_TOTAL: "总价",
			COL_TOTAL_DEPOSIT: "预付金额",
			COL_EPO_TYPE: "EPO类型",
			COL_NOTICE_TYPE: "Notice Type",
			COL_NO_OF_RECEIVING: "收货几次？",

			BTN_VIEW: "查看",
			BTN_DELETE: "删除",

			TEXT_SAVE_AS_DRAFT: "另存为草稿"
		},
		ORDER: {
			LISTING_TITLE: "EPO列表",
			CREATE_ORDER_TITLE: "Create new Order",
			EPO_ORDER_TITLE: "EPO Order for {epo_no}",
			EPO_INFO_TITLE: "EPO Info",
			WAREHOUSE_DETAILS_TITLE: "Warehouse Details",
			FROM_WAREHOUSE_TITLE: "From Which Warehouse",
			WAY_TO_SHIP_TITLE: "How would you like to ship?",
			LOADING_PORT_TITLE: "Loading Port",
			FORWARDER_INFORMATION_TITLE: "Forwarder Details",
			DELIVERY_ADDRESS_TITLE: "Destination",
			DESTINATION_PORT_TITLE: "Destination Port",
			SUPPLIER_DETAILS_TITLE: "Supplier Info",
			PRODUCT_DETAILS_TITLE: "Courier / Product Details",
			SPECIAL_REQUEST_TITLE: "Special Request",
			CUSTOMER_NOTE_TITLE: "Customer Notes",
			LBL_EPO_NO: "EPO单号",
			LBL_EPO_TYPE: "EPO Type",
			LBL_PO_EXISTS: "PO Exists?",
			LBL_CURRENCY: "Currency",
			LBL_COMPANY_NAME: "Company Name",
			LBL_PIC: "Person In Charge",
			LBL_EMAIL_ADDRESS: "Email Address",
			LBL_CONTACT_NUMBER: "Mobile Contact No.",
			LBL_BILL_ADDRESS: "Bill Address",
			LBL_OFFICE_CONTACT: "Office Contact No.",
			LBL_OFFICE_FAX_CONTACT: "Office Fax No.",
			LBL_CUSTOMER: "Customer",
			LBL_MARKING: "Customer 客户代号",
			LBL_SUPPLIER: "Supplier",
			LBL_SUPPLIER_ORDER_NO: "Supplier Order No",
			LBL_SUPPLIER_BANK_NAME: "Supplier Bank Name",
			LBL_SUPPLIER_BANK_ACCOUNT_NAME: "Supplier Bank Account Name",
			LBL_SUPPLIER_BANK_ACCOUNT_NO: "Supplier Bank Account No.",
			LBL_EXISTS_PO_NO: "Exists PO No.",
			LBL_DEPOSIT: "Deposit",
			LBL_ESTIMATE_DATE_DELIVERY: "Estimate Delivery Date",
			LBL_WAREHOUSE_TYPE: "Warehouse Type",
			LBL_WAREHOUSE: "Warehouse",
			LBL_PRODUCT_REMARK: "Remarks",
			LBL_EPO_SUBMIT_DATE: "EPO Date",
			LBL_DATE_FROM: "日期从",
			LBL_DATE_TO: "日期至",
			LBL_EPO_STATUS: "EPO Status",

			LBL_SUB_TOTAL: "Sub Total",
			LBL_TOTAL_DISCOUNT: "Total Discount",
			LBL_GRAND_TOTAL_AMOUNT: "Grand Total",

			EPO_TYPE_SELECTION_ORDER: "Order",
			EPO_TYPE_SELECTION_COURIER: "Courier",

			WAREHOUSE_SELECTION_OTHERS: "Others",

			PH_PIC: "Name",
			PH_SPECIAL_REQUEST: "Write a Message...",
			PH_LOADING_PORT: "输入 Loading Port",
			PH_DESTINATION_PORT: "输入 Destination Port",

			BTN_NEW_SUPPLIER: "New Supplier",

			COL_EPO_NO: "EPO No",
			COL_CREATED_AT: "Created On",
			COL_SHIPMENT_TYPE: "Shipment Type",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_WAREHOUSE: "Warehouse",
			COL_SUPPLIER: "Supplier",
			COL_COUNTRY: "From/To Country",
			COL_STATUS: "Status",
			COL_EFFECTIVE_ON: "Effective On",
			COL_PRODUCT_NAME: "Commodity Desc",
			COL_REF_TYPE: "Container Type",
			COL_MARKING: "Customer 客户代号",

			PH_AUTO_DOC_NO: "[Auto]",

			SHIPPING: {
				LBL_TYPE: "Shipment Type",
				LBL_MODE: "Shipment Mode",
				LBL_MOVEMENT: "Movement Type",
				LBL_TERM: "Shipment Term"
			},

			SUPPLIER: {
				LBL_NAME: "Supplier/Forwarder Name",
				LBL_ADDRESS: "Address",
				LBL_POSTCODE: "Postal Code",
				LBL_CITY: "City",
				LBL_STATE: "State",
				LBL_CONTACT_NUMBER: "Supplier/Forwarder Contact Number",
				LBL_CONTACT_NUMBER_CALLING_CODE: "Supplier/Forwarder Calling Code"
			},

			DELIVERY: {
				LBL_ADDRESS_NAME: "Destination Address",
				LBL_ADDRESS: "Address",
				LBL_BUILDING_NAME: "Building Name",
				LBL_UPSTAIRS: "upstairs",
				LBL_COUNTRY: "Country",
				LBL_STATE: "State",
				LBL_CITY: "City",
				LBL_POSTCODE: "Postal Code",
				LBL_BRN: "Business Register Number"
			},

			PRODUCT: {
				COL_COURIER: "Courier",
				COL_ITEM_CODE: "Item Code",
				COL_PRODUCT_NAME: "Product Name",
				COL_BRAND: "Brand",
				COL_CATEGORY_GROUP: "Type",
				COL_CATEGORY_GROUP_TYPE: "Type Selection",
				COL_CATEGORY_GROUP_OTHERS: "Type Others",
				COL_QUANTITY: "Qty",
				COL_UOM: "Uom",
				COL_UNIT_PRICE: "Unit Price",
				COL_DISCOUNT_AMOUNT: "Discount Amount",
				COL_TOTAL_AMOUNT: "Total Amount",
				COL_REMARKS: "Remark",
				COL_PHOTO: "Photo",

				LBL_CONSIGNMENT_NO: "Consignment No",

				BTN_COPY: "Copy",
				BTN_ADD: "Add",
				BTN_DELETE: "Delete",
				BTN_UPLOAD: "Upload",
				BTN_CHANGE_CATEGORY_GROUP: "Modify Category Group",
				BTN_ADD_CONSIGNMENT_NO: "Add Consignment No",
				BTN_UPLOAD_PHOTO: "Upload Product Image",
				BTN_SUPPORT_DOCUMENT: "Upload Support Document",

				EXAMPLE_ENTER_PRODUCT_CATEGORY: "For Example Commodity Desc: Shirt, Shoes, Toy",
				EXAMPLE_ENTER_BRAND: "For Example 输入 Multiple Brand: Addidas, Nike",

				PH_SELECT_CATEGORY_GROUP: "Please Select Category Group",
				PH_GOODS_AMOUNT_FROM: "输入 Amount From",
				PH_GOODS_AMOUNT_TO: "输入 Amount To",
				PH_GOODS_AMOUNT_CURRENCY_SYMBOL: "输入 Currency Symbol",

				MSG_GOODS_AMOUNT_FROM_GREATER_TO: "Amount To must be greater than Amount From",
				MSG_GOODS_AMOUNT_FROM_REQUIRED: "Amount From Required"
			},

			CATEGORY_GROUP_TYPE: {
				MODAL_TITLE: "Category Type Details",

				LBL_CATEGORY_GROUP: "Goods Type",
				LBL_CATEGORY_GROUP_TYPE: "Selection of {category_group_name} product",
				LBL_OTHER_CATEGORY_GROUP_TYPE: "Discribe for {category_group_name} product",
				LBL_VALUABLE_OBJECTS: "Valuable objects",
				LBL_FRAGILE_ITEMS: "Fragile items",
				LBL_OTHER_SENSITIVE_ITEMS: "Other Sensitive Items",
				LBL_FAKE_BRANDS: "Fake Brands",

				PH_OTHER_CATEGORY_GROUP_TYPE: "Please input and discribe for {category_group_name} product",
				PH_FAKE_BRANDS: "Please input and discribe for Fake Brands"
			},
			UPLOAD: {
				PRODUCT_IMAGE: "Upload Product Image",
				SUPPORTED_DOCUMENT: "Upload Supported Document",
				LBL_UPLOAD_PHOTO: "Click to select the images.",
				LBL_UPLOAD_FILE: "Click to select the file.",
				SUPPORT_IMAGE_SIZE: "Supported size should be less than 1 MB!",
				MSG_SUPPORT_IMAGE_SIZE_FORMAT: "Supported size should be less than 1 MB and format .jpg, .jpeg, .png",
				SUPPORT_DOCUMENT_SIZE: "Supported size should be less than 10 MB!",
				MSG_SUPPORT_FILE_SIZE_FORMAT: "Supported size should be less than 10 MB! and format .xls, .xlsx, .pdf, .doc, .docs, .csv"
			},

			NEW_SUPPLIER: {
				MODAL_TITLE: "Create New Supplier"
			},

			BTN_SUBMIT: "Submit",
			BTN_RESET: "Reset",
			SUCCESS: "EPO Order has been generate and EPO No: {epo_no}.",
			FAILED: "Oopps ... ..., Sorry EPO Order Failed to Generate. Please try again.",
			MSG_FAILED_UPLOAD_IMGAE: "Sorry Product Image Failed to Upload",
			MSG_FAILED_UPLOAD_DOCUMENT: "Sorry Document Failed to Upload",
			MSG_NO_SUPPLIER: "Supplier not found. Please create a new supplier before create order",
			MSG_NO_ADDRESS: "Delivery Addresses not found. Please create a new delivery address before requesting for a quote",
			MSG_BILL_ADDRESS_NOT_FOUND: "Billing Address not found. Please set.",

			MSG_GRAND_TOTAL_AMOUNT_NEGATIVE: "Grand Total amount cannot be negative",
			MSG_PRODUCT_TOTAL_AMOUNT_NEGATIVE: "Product Total amount cannot be negative",
			MSG_PRODUCT_UNIT_PRICE_EMPTY: "Warning! your product unit price contain zero or empty value",
			MSG_SUCCESFUL_SAVE_AS_DRAFT: "Successful save as draft",
			MSG_BLOCK_EDIT: "You are not allow to edit this EPO Order.",
			MSG_DOCUMENT_TYPE: "No Document Type matched for current 客户代号. Please contact admin",
			MSG_DOCUMENT_NUMBER: "No Document Number matched for current 客户代号. Please contact admin",
			MSG_EPO_FORMAT_SETTINGS: "EPO Format Setting not matched. Please contact customer service",

			MSG_CONFIRMATION_SUBMIT: "Please ensure all details are correct before submitting.",
			MSG_PERSONAL_DATA_INCOMPLETE: "Personal Data Incomplete. Please complete first",
			RESET_FORM_CONFIRMATION: "全部 Information will be reset. Are you sure?",
			MSG_BILL_ADDRESS_INCOMPLETE: "Billing Address Incomplete",

			ERROR: {
				MSG_SUPPLIER_ADDRESS_EMPTY: "Supplier Address is empty",
				MSG_DELIVERY_ADDRESS_EMPTY: "Delivery Address is empty",
				MSG_BILLING_ADDRESS_EMPTY: "Billing Address is empty",
				CONTACT_PIC_REQUIRED: "CUstomer Name is empty",

				MSG_DOCUMENT_TYPE_EMPTY: "Missing Document Type Settings",
				MSG_DOCUMENT_NUMBER_EMPTY: "Missing Document Number Settings",
				MSG_EPO_SETTING_EMPTY: "Missing Epo Format Setting"
			}
		}
	},
	MASTER_DATA: {
		COUNTRIES: {
			COUNTRIES_NAME: "Country Name",
			CALLING_CODE: "Calling Code",
			LISTING_TITLE: "Country Listing",
			LBL_COUNTRIES_NAME: "Country Name",
			PH_ENTER_COUNTRIES_NAME: "输入 Country Name",
			PH_ENTER_COUNTRIES_CALLING_CODE: "Calling Code",
			DETAIL_TITLE: "Country Detail",
			MODULE_NAME: "Country",
			LBL_COUNTRIES_CALLING_CODE: "Calling Code"
		},
		STATES: {
			LISTING_TITLE: "State Listing",
			LBL_STATES_NAME: "State Name",
			LBL_COUNTRIES: "Country",
			LBL_STATES_CODE: "State Code",
			COL_NAME: "State Name",
			COL_COUNTRY: "Country",
			PH_ENTER_STATES_NAME: "输入 State Name",
			PH_ENTER_STATES_CODE: "输入 State Code",
			MODULE_NAME: "State",
			DETAIL_TITLE: "State Detail"
		},
		CITIES: {
			LISTING_TITLE: "City Listing",
			LBL_CITY_NAME: "City Name",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",
			COL_NAME: "City Name",
			COL_COUNTRY: "Country",
			COL_STATE: "State",
			PH_ENTER_CITY_NAME: "输入 City Name",
			DETAIL_TITLE: "City Detail",
			MODULE_NAME: "City"
		},
		DISTRICT: {
			LISTING_TITLE: "District Listing",
			LBL_DISTRICT_NAME: "District Name",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",
			LBL_CITY: "City",
			COL_NAME: "District Name",
			COL_COUNTRY: "Country",
			COL_STATE: "State",
			COL_CITY: "City",
			MODULE_NAME: "District",
			DETAIL_TITLE: "District Detail"
		},
		POSTCODES: {
			LISTING_TITLE: "Postcode Listing",
			LBL_POSTCODE: "Postcode",
			LBL_CITY: "City",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",
			LBL_DISTRICT: "District",
			COL_POSTCODE: "Postcode",
			COL_COUNTRY: "Country",
			COL_STATE: "State",
			COL_CITY: "City",
			COL_DISTRICT: "District",
			PH_ENTER_POSTCODE_NAME: "输入 Postcode",
			PH_ENTER_POSTCODE: "输入 Postcode",
			DETAIL_TITLE: "Postcode Detail",
			MODULE_NAME: "Postcode"
		},
		PORT: {
			MODULE_NAME: "Port",
			LISTING_TITLE: "Port Listing",
			DETAIL_TITLE: "Port Detail",
			LBL_PORT_CODE: "Port Code",
			LBL_PORT_NAME: "Port Name",
			LBL_COUNTRIES: "Country",
			COL_PORT_CODE: "Port Code",
			COL_PORT_NAME: "Port Name",
			COL_COUNTRY_NAME: "Country"
		},
		CURRENCIES: {
			MODULE_NAME: "Currencies",
			LISTING_TITLE: "Currencies Listing",
			DETAIL_TITLE: "Currencies Detail",
			LBL_CURRENCIES_NAME: "Currency Name",
			LBL_CURRENCIES_CODE: "Currency Code",
			COL_CURRENCY_CODE: "Currency Code",
			COL_CURRENCY_NAME: "Currency Name",
			LBL_SYMBOL_LEFT: "Symbol Left",
			LBL_SYMBOL_RIGHT: "Symbol Right",
			LBL_DECIMAL_PLACE: "Exchange Rate Decimal Place",
			LBL_EXCHANGE_CURRENCY: "Exchange Currency Rate",
			COL_EXCHANGE_CURRENCY: "Exchange Currency",
			COL_CONVERSION_FACTOR: "Conversion Factor",
			COL_RATE: "Rate"
		},
		CURRENCIES_RATE: {
			BTN_CURRENCT_RATE_HISTORY: "Currency Rate History",
			HISTORY_TITLE: "Currency Rate History",
			LBL_DATE_FROM: "日期从",
			LBL_DATE_TO: "To",
			LABEL_CURRENCY: "Currency",
			LABEL_EXCHANGE_CURRENCY: "Exchange Currency",
			COL_DATE: "Date",
			COL_CURRENCY: "Currency",
			COL_EXCHANGE_CURRENCY: "Exchange Currency",
			COL_RATE: "Rate"
		},
		UOM: {
			MODULE_NAME: "UOM",
			DETAIL_TITLE: "Stock UOM Detail",
			LISTING_TITLE: "Stock UOM Listing",
			LBL_UOM_CODE: "Stock UOM Code",
			LBL_UOM_NAME: "Stock UOM Name",
			COL_UOM_CODE: "Stock UOM Code",
			COL_UOM_NAME: "Stock UOM Name",
			COL_PERCENTAGE: "Percentage",
			COL_USE_INVOICE: "Use for Invoice",
			COL_USE_RECEIVING: "Use for Receiving",
			COL_USE_EPO: "Use EPO/Receiving/Inv",
			LBL_UOM_QUOTATION_DESCRIPTION: "Quotation Description"
		},
		REFERENCE_TYPE: {
			MODULE_NAME: "Reference Type",
			DETAIL_TITLE: "Reference Type Detail",
			LISTING_TITLE: "Reference Type Listing",
			LBL_REF_TYPE_CODE: "Reference Type Code",
			LBL_REF_TYPE_NAME: "Reference Type Name",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			COL_CODE: "Reference Type Code",
			COL_NAME: "Reference Type Name",
			COL_SHIPMENT_TYPE_NAME: "Shipment Type",
			LBL_QUOTATION: "Quotation",
			COL_FAVOURITE: "Favourite"
		},
		SHIPPER: {
			MODULE_NAME: "Shipper",
			DETAIL_TITLE: "Shipper Detail",
			LISTING_TITLE: "Shipper Listing",
			LBL_SHIPPER_NAME: "Shipper Name",
			LBL_SHIPPER_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_SHIPPER_EMAIL: "Email",
			LBL_SHIPPER_WAREHOUSE_ID: "Warehouse",
			LBL_SHIPPER_COUNTRY_ID: "Country",
			LBL_SHIPPER_STATE_ID: "State",
			LBL_SHIPPER_CITY_ID: "City",
			LBL_SHIPPER_OTHER_CITY_ID: "Other City",
			LBL_SHIPPER_POSTCODE_ID: "Postcode",
			LBL_SHIPPER_OTHER_POSTCODE_ID: "Other Postcode",
			LBL_SHIPPER_ADDRESS: "Address",
			LBL_SHIPPER_OFFICE_TEL_NO: "Tel No.",
			LBL_SHIPPER_OFFICE_FAX_NO: "Fax No.",
			LBL_SHIPPER_URL: "URL",
			LBL_SHIPPER_REMARK: "Remark",
			COL_SHIPPER_NAME: "Shipper Name",
			COL_SHIPPER_MOBILE_CONTACT_NO: "Mobile Contact No.",
			COL_SHIPPER_EMAIL: "Shipper Email"
		},
		COURIER_COMPANY: {
			LISTING_TITLE: "Courier Company Listing",
			CREATE_TITLE: "Create New Courier Company",
			DETAIL_TITLE: "Courier Company Detail",
			EDIT_TITLE: "Edit Courier Company Detail",

			LBL_COURIER_COMPANY_CODE: "Code",
			LBL_COURIER_COMPANY_NAME: "Name",
			LBL_COURIER_COMPANY_COUNTRY: "Country",
			LBL_COURIER_COMPANY_DESCRIPTION: "Description",

			MODULE_NAME: "Courier Company",

			COL_CODE: "Code",
			COL_NAME: "Name"
		},
		SHIPPING_LINE: {
			MODULE_NAME: "Shipping Line",
			DETAIL_TITLE: "Shipping Line Detail",
			LISTING_TITLE: "Shipping Line Listing",
			LBL_SHIPPING_LINE_NAME: "Shipping Line Name",
			LBL_SHIPPING_LINE_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_SHIPPING_LINE_EMAIL: "Email",
			LBL_SHIPPING_LINE_COUNTRY_ID: "Country",
			LBL_SHIPPING_LINE_STATE_ID: "State",
			LBL_SHIPPING_LINE_CITY_ID: "City",
			LBL_SHIPPING_LINE_OTHER_CITY_ID: "Other City",
			LBL_SHIPPING_LINE_POSTCODE_ID: "Postcode",
			LBL_SHIPPING_LINE_OTHER_POSTCODE_ID: "Other Postcode",
			LBL_SHIPPING_LINE_ADDRESS: "Address",
			LBL_SHIPPING_LINE_OFFICE_TEL_NO: "Tel No.",
			LBL_SHIPPING_LINE_OFFICE_FAX_NO: "Fax No.",
			LBL_SHIPPING_LINE_URL: "URL",
			LBL_SHIPPING_LINE_REMARK: "Remark",
			COL_SHIPPING_LINE_NAME: "Shipping Line Name",
			COL_SHIPPING_LINE_MOBILE_CONTACT_NO: "Mobile Contact No.",
			COL_SHIPPING_LINE_EMAIL: "Shipper Email"
		},
		SHIPMENT_TYPE: {
			MODULE_NAME: "Shipment Type",
			DETAIL_TITLE: "Shipment Type Detail",
			LISTING_TITLE: "Shipment Type Listing",
			LBL_SHIPMENT_TYPE_NAME: "Shipment Type Name",
			COL_NAME: "Shipment Type Name",
			CHECKBOX_CATERGORY_TYPE_COST_TITLE: "Multiple Route & Shipment Mode",
			LBL_DEFAULT_SELECT: "Default (Customer Portal)",
			MODULE_QUOTATION: "Quotation",
			MODULE_EPO: "EPO",
			LBL_CATERGORY_TYPE_COST: "Category Type Cost",
			LBL_DEFAULT: "Default"
		},
		SHIPMENT_MODE: {
			LISTING_TITLE: "Shipment Mode Listing",
			CREATE_TITLE: "Create New Shipment Mode",
			DETAIL_TITLE: "Shipment Mode Detail",
			EDIT_TITLE: "Edit Shipment Mode Detail",

			COSTING_STRUCTURE_TITLE: "Base Cost Structure",
			EXCEPTIONAL_COST_STRUCTURE_TITLE: "Exceptional Cost Structure",

			LBL_SHIPMENT_MODE_CODE: "Shipment Mode Code",
			LBL_SHIPMENT_MODE_NAME: "Shipment Mode Name",
			LBL_SHIPMENT_TYPE: "Supported Shipment Type",
			LBL_QUOTATION_REMARK: "{shipment_type} Quotation Remark",
			LBL_COST_SETUP: "Cost Setup?",

			MODULE_NAME: "Shipment Mode",

			COL_CODE: "Shipment Mode Code",
			COL_NAME: "Shipment Mode Name",
			COL_SHIPMENT_TYPE_NAME: "Shipment Type",

			COST_STRUCTURE: {
				LISTING_TITLE: "Costing Structure",
				EDIT_COST_STRUCTURE_TITLE: "Edit Costing Structure",
				BTN_EXCEPTION_COST: "Exptional Cost",
				BTN_BASE_COST: "Base Cost",
				BTN_COST: "Cost Structure",

				COL_M3: "M3",
				COl_CONDITION: "Condition",
				COl_MIN_CHARGES: "Min Charges",
				COl_RATE_TYPE: "Type",
				COl_CHARGES: "Charges",
				COl_ADDITIONAL_CHARGES: "Additional Charges",
				MODULE_NAME: "Cost Structure"
			},
			MODULE_QUOTATION: "Quotation (Default)",
			MODULE_EPO: "EPO (Default)",
			LBL_DEFAULT_SELECT: "Default (Customer Portal)"
		},
		CATEGORY_TYPE: {
			LISTING_TITLE: "Category Type Listing",
			CREATE_TITLE: "Create New Category Type",
			DETAIL_TITLE: "Category Type Detail",
			EDIT_TITLE: "Edit Category Type Detail",

			LBL_CATEGORY_GROUP_CODE: "Category Type Code",
			LBL_CATEGORY_GROUP_NAME: "Category Type Name",
			LBL_CATEGORY_TYPE_DESCRIPTION: "Category Type Description ",
			BTN_SET_SENSITIVE: "Set Sensitive As Default Wording",
			MODULE_NAME: "Category Type",

			COL_CATEGORY_TYPE_CODE: "Category Type Code",
			COL_CATEGORY_TYPE_NAME: "Category Type Name",
			COL_USE_FOR_INVOICE: "Use For Invoice",
			COL_PRINT_ON_QUOTATION: "打印 on Quotation",
			COL_DEFAULT_QUOTE: "Must Quote",
			COL_SPECIAL_CHARGES: "Special Charges",
			SENSITIVE_TITLE: "Sensitive",
			LBL_MUST_QUOTE: "Must Quote",
			LBL_QUOTE_WITH_SPECIAL_ADD_CHARGES: "Special Addition Charges"
		},
		CATEGORY_PARENT: {
			LISTING_TITLE: "Product Category (Parent) Listing",
			CREATE_TITLE: "Create New Product Category (Parent)",
			DETAIL_TITLE: "Product Category (Parent) Detail",
			EDIT_TITLE: "Edit Product Category (Parent) Detail",

			LBL_CATEGORY_CODE: "Product Category Code",
			LBL_CATEGORY_NAME: "Product Category Name",
			LBL_CATEGORY_GROUP: "Product Category Type",
			LBL_CATEGORY_PARENT: "Product Category Parent",

			MODULE_NAME: "Product Category",

			COL_CATEGORY_CODE: "Product Category Code",
			COL_CATEGORY_NAME: "Product Category Name",
			COL_CATEGORY_GROUP: "Category Type",
			COL_CATEGORY_PARENT: "Product Category Parent",
			ROOT_SELECTION: "Parent"
		},
		SENSITIVE_TYPE: {
			LISTING_TITLE: "Sensitive Type Listing",
			CREATE_TITLE: "Create New Sensitive Type",
			DETAIL_TITLE: "Sensitive Type Detail",
			EDIT_TITLE: "Edit Sensitive Type Detail",

			LBL_CATEGORY_GROUP: "Category Type",
			LBL_CATEGORY_GROUP_TYPE_CODE: "Sensitive Type Code",
			LBL_CATEGORY_GROUP_TYPE_NAME: "Sensitive Type Name",
			LBL_CATEGORY_GROUP_TYPE_DESCRIPTION: "Description",

			MODULE_NAME: "Sensitive Type",

			COL_CODE: "Sensitive Type Code",
			COL_NAME: "Sensitive Type Name",
			COL_CATEGORY_TYPE: "Category Type",
			COL_USE_FOR_EPO: "Use for EPO/Receiving",
			COL_PRINT_ON_QUOTE: "打印 on Quotation"
		},
		CATEGORY: {
			LISTING_TITLE: "Product Category (Lvl 2) Listing",
			CREATE_TITLE: "Create New Product Category (Lvl 2)",
			DETAIL_TITLE: "Product Category (Lvl 2) Detail",
			EDIT_TITLE: "Edit Product Category (Lvl 2) Detail",

			LBL_CATEGORY_CODE: "Product Category Code",
			LBL_CATEGORY_NAME: "Product Category Name",
			LBL_CATEGORY_GROUP: "Product Category Type",
			LBL_CATEGORY_PARENT: "Product Category Parent",
			LBL_INVOICE_DESC: "Invoice Description",
			LBL_SENSITIVE_TYPE: "Sensitive Type",

			MODULE_NAME: "Product Category",

			COL_CATEGORY_CODE: "Product Category Code",
			COL_CATEGORY_NAME: "Product Category Name",
			COL_CATEGORY_GROUP: "Category Type",
			COL_CATEGORY_PARENT: "Product Category Parent",
			ROOT_SELECTION: "Parent",
			MSG_CATEGORY_DUPLICATION: "{code} is not available."
		},
		SHIPMENT_TERMS: {
			LISTING_TITLE: "Shipment Terms Listing",
			CREATE_TITLE: "Create New Shipment Terms",
			DETAIL_TITLE: "Shipment Terms Detail",
			EDIT_TITLE: "Edit Shipment Terms",
			MODULE_NAME: "Shipment Terms",

			LBL_SHIPMENT_TERMS_CODE: "Shipment Terms Code",
			LBL_SHIPMENT_TERMS_NAME: "Shipment Terms Name",

			LBL_MOVEMENT_TYPE: "Movement Type",
			LBL_DESCRIPTION: "Description",

			COL_CODE: "Shipment Terms Code",
			COL_NAME: "Shipment Terms Name",
			COL_MOVEMENT_TYPE: "Movement Type"
		},
		CONSIGNEE: {
			MODULE_NAME: "Consignee",
			DETAIL_TITLE: "Consignee Detail",
			LISTING_TITLE: "Consignee Listing",
			LBL_NAME: "Consignee Name",
			LBL_CONSIGNEE_GROUP_NAME: "Consignee Group Name",
			LBL_CONSIGNEE_GROUP: "Consignee Group",
			LBL_EMAIL: "Email",
			LBL_ADDRESS: "Address",
			LBL_COUNTRY: "Country",
			LBL_STATE: "State",
			LBL_CITY: "City",
			LBL_POSTCODE: "Postcode",
			LBL_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_OFFICE_TEL_NO: "Office Tel No.",
			LBL_OFFICE_FAX_NO: "Office Fax No.",
			LBL_URL: "URL",
			LBL_REMARK: "Remark",
			COL_NAME: "Consignee Name",
			COL_GROUP_NAME: "Consignee Group Name",
			COL_MOBILE_CONTACT_NO: "Contact No.",
			COL_EMAIL: "Email",
			COL_ADDRESS: "Address"
		},
		CONSIGNEE_GROUP: {
			MODULE_NAME: "Consignee Group",
			DETAIL_TITLE: "Consignee Group Detail",
			LISTING_TITLE: "Consignee Group Listing",
			LBL_NAME: "Consignee Group Name",
			LBL_CODE: "Consignee Group Code",
			COL_NAME: "Consignee Name",
			COL_CODE: "Consignee Group Code",
			COL_REFERENCE_COUNT: "Reference Count",
			COL_EMAIL: "Email",
			COL_ADDRESS: "Address"
		},
		FORWARDER: {
			MODULE_NAME: "Forwarder",
			DETAIL_TITLE: "Forwarder Detail",
			LISTING_TITLE: "Forwarder Listing",
			LBL_NAME: "Forwarder Name",
			LBL_FORWARDER_GROUP_NAME: "Forwarder Group Name",
			LBL_FORWARDER_GROUP: "Forwarder Group",
			LBL_EMAIL: "Email",
			LBL_ADDRESS: "Address",
			LBL_COUNTRY: "Country",
			LBL_STATE: "State",
			LBL_CITY: "City",
			LBL_POSTCODE: "Postcode",
			LBL_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_OFFICE_TEL_NO: "Office Tel No.",
			LBL_OFFICE_FAX_NO: "Office Fax No.",
			LBL_URL: "URL",
			LBL_REMARK: "Remark",
			COL_NAME: "Forwarder Name",
			COL_GROUP_NAME: "Forwarder Group Name",
			COL_MOBILE_CONTACT_NO: "Contact No.",
			COL_EMAIL: "Email",
			COL_ADDRESS: "Address"
		},
		FORWARDER_GROUP: {
			MODULE_NAME: "Forwarder Group",
			DETAIL_TITLE: "Forwarder Group Detail",
			LISTING_TITLE: "Forwarder Group Listing",
			LBL_NAME: "Forwarder Group Name",
			LBL_CODE: "Forwarder Group Code",
			COL_NAME: "Forwarder Group Name",
			COL_CODE: "Forwarder Group Code",
			COL_REFERENCE_COUNT: "Reference Count"
		},
		NOTICE_TYPE: {
			LISTING_TITLE: "Notice Type Listing",
			CREATE_TITLE: "Create New Notice Type",
			DETAIL_TITLE: "Notice Type Detail",
			EDIT_TITLE: "Edit Notice Type Detail",

			LBL_NOTICE_TYPE_CODE: "Notice Type Code",
			LBL_NOTICE_TYPE_DESC: "Notice Type Desc",
			LBL_NOTICE_TYPE_GROUP: "Notice Type Group",
			LBL_NOTICE_TYPE_PAYMENT_TERMS_DESCRIPTION: "Quotation Payment Terms Description",
			LBL_NOTICE_TYPE_INSTRUCTION: "Notice Type Instruction",
			LBL_ORDER_RECEIPT: "Order Receipt",
			LBL_CASH_PAYMENT: "Cash Payment",
			LBL_DUE_DAYS: "Due Days",
			LBL_EPO_REMARK: "EPO Remark",
			LBL_WAREHOUSE: "Warehouse",
			LBL_EPO_IMPORTANT_NOTICE: "Important Notice",
			LBL_EPO_MARKING_REMARK: "客户代号 Remark",
			LBL_SUPPLIER_BANK: "Supplier Bank Required",
			LBL_EPO_SUPPLIER_BANK_REMARK: "Supplier Bank Remark",
			MODULE_NAME: "Notice Type",

			COL_NOTICE_TYPE_CODE: "Notice Type Code",
			COL_NOTICE_TYPE_DESC: "Notice Type Desc",
			COL_NOTICE_TYPE_GROUP: "Notice Type Group",
			COL_AGENT: "Agent",
			COL_NOTICE_TYPE_INSTRUCTION: "Notice Type Instruction",
			COL_ORDER_RECEIPT: "Order Receipt",
			COL_CASH_PAYMENT: "Cash Payment",
			COL_DUE_DAYS: "Due Days",

			BTN_ADD_NEW_EPO_REMARK: "Add New EPO Remark",
			BTN_ADD_REMOVE_EPO_REMARK: "Remove EPO Remark"
		},
		COMPANY: {
			CREATE_TITLE: "Create New Company",
			DETAIL_TITLE: "Company Detail",
			EDIT_TITLE: "Edit Company",
			MODULE_NAME: "Company",
			LISTING_TITLE: "Company Listing",

			LBL_COMPANY_NAME: "Company Name",
			LBL_COMPANY_CODE: "Company Code",
			LBL_COMPANY_REGISTER_NUMBER: "Company Business Register Number",
			LBL_COMPANY_EMAIL: "Email",
			LBL_COMPANY_GROUP_NAME: "Group Name",
			LBL_TAX_CODE: "Tax Code",

			LBL_COMPANY_GROUP: "Company Group",
			LBL_COMPANY_GROUP_CODE: "Company Group Code",

			LBL_CONTACT_NUMBER: "Contact Number",
			LBL_FAX_NUMBER: "Fax Number",

			LBL_BUSINESS_NATURE: "Business Nature",
			LBL_WEBSITE: "Website",
			LBL_COMPANY_ADDRESS: "Company Address",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",
			LBL_CITY: "City",
			LBL_POSTCODE: "Postcode",
			LBL_COMPANY_LOGO: "Company Logo",
			LBL_TAX_TYPE: "Tax Type",
			LBL_TAX_REGISTERDATE: "Register Date",
			LBL_TAX_NUMBER: "Register Number",
			LBL_TAX_REGISTER_TYPE: "Tax Register Type",
			LBL_TAX_EFFECTIVEDATE: "Effective Date",
			LBL_TAX_VALIDATIONDATE: "Verify Date",
			LBL_TAX_PIC: "Person Incharge",
			LBL_BANK: "Bank(s)",

			COL_COMPANY_GROUP_CODE: "Code",
			COL_GL_SALES_ACCOUNT: "GL Sales Account",
			NO_TAX: "No Tax",

			MSG_FAILED_TOUPLOAD_IMAGE: "Failed To Upload Company Logo.",

			COL_COMPANY_CODE: "Company Code",
			COL_COMPANY_NAME: "Company Name",
			COL_AGENT: "Agent",

			BANK_TABLE: {
				COL_SHOW: "Show",
				COL_ACCOUNT_NAME: "Account Name",
				COL_ACCOUNT_NO: "Account No",
				COL_BANK_NAME: "Bank Name"
			},

			MSG_IMG_SUPPORTED: "*Support JPG,JPEG,PNG,GIF file, file size not more that 500kb.",
			MSG_IMAGE_SIZE: "*image size(W x L) 590x208",

			MSG_IMAGE_ERROR_TITLE: "File size is too huge",
			MSG_IMAGE_ERROR_SIZE: "The size of file should be less than 500 KB"
		},
		WAREHOUSE: {
			LISTING_TITLE: "Warehouse List",
			CREATE_TITLE: "Create New Warehouse",
			DETAIL_TITLE: "Warehouse Detail",
			EDIT_TITLE: "Edit Warehouse",

			MODULE_NAME: "Warehouse",

			LBL_WAREHOUSE_CODE: "WH Code",
			LBL_WAREHOUSE_NAME: "WH Name",

			COL_WAREHOUSE_CODE: "Warehouse Code",
			COL_WAREHOUSE_NAME: "Warehouse Name",
			COL_STICKER_FEE: "Sticker Fee",

			LBL_CONTACT: "Contact Person",
			LBL_COMPANY: "Company",
			LBL_WAREHOUSE_ADDRESS: "Address",
			LBL_SUPPORTED_SHIPMENT_TYPE: "Supported Shipment Type",
			LBL_QUOTATION_DESC: "Quotation Desc",
			LBL_MOVEMENT_TYPE: "Movement Type",
			LBL_API_URL: "GIM WMS API URL",
			LBL_GOODS_RECEIPT_PAYABLE_NOTE_PIC: "Goods Receipt/Payable Note Contact Person",
			LBL_QUOTE_AREA: "Replace {area} with State & City",
			LBL_WAREHOUSE_NAME_PRINT_IN_EPO: "Warehouse Name 打印 on EPO",
			LBL_FORWARDER: "Forwarder",
			LBL_FORWARDER_COUNTRY_ID: "Forwarder Country ID",
			LBL_FORWARDER_STATE_ID: "Forwarder State ID",
			YES_STATUS_VAL: "Yes",

			COL_PIC: "Person In Charge",
			COL_CONTACT_NUMBER: "Contact Number",
			COL_WECHAT_ID: "WeChat ID",
			COL_EMAIL: "Email",
			COL_IS_DEFAULT: "Default",
			COL_IS_SHOW: "Show",
			COL_REMARK: "Remark",
			COL_CONTACT_CALLING_CODE: "Contact Number Calling Code",
			LBL_EPO_SPECIAL_REMARK: "EPO Special Remark",
			LBL_EPO_FOOTER_REMARK: "EPO Footer Remark",
			LBL_EPO_WAREHOUSE_CONTACT_REMARK: "EPO Warehouse Contact Remark",
			LBL_YES: "Yes",
			LBL_STATE: "state"
		},
		WAREHOUSE_LOCATION: {
			MODULE_NAME: "Warehouse Location",
			LISTING_TITLE: "Warehouse Location Listing",
			LBL_WAREHOUSE_LOCATION_CODE: "Location Code",
			LBL_WAREHOUSE_LOCATION_NAME: "Location Name",
			LBL_WAREHOUSE: "Warehouse",
			CREATE_TITLE: "Create New Warehouse Location",
			DETAIL_TITLE: "Warehouse Location Detail",
			EDIT_TITLE: "Edit Warehouse Location",

			COL_LOCATION_CODE: "Location Code",
			COL_LOCATION_NAME: "Location Name",
			COL_WAREHOUSE_NAME: "Warehouse Name"
		},
		MAINTAIN_COURIER_SERVICE: {
			MODULE_NAME: "Maintain Courier Service",
			LISTING_TITLE: "Maintain Courier Service Listing",
			CREATE_TITLE: "Create New Maintain Courier Service",
			DETAIL_TITLE: "Maintain Courier Service Detail",
			EDIT_TITLE: "Edit Maintain Courier Service",

			COURIER_SERVICE_ID: "Courier Service ID",
			COURIER_SERVICE_DESCRIPTION: "Courier Service Description",
			REMARK: "Remark"
		},
		MAINTAIN_AGENT: {
			MODULE_NAME: "Maintain Agent",
			LISTING_TITLE: "Maintain Agent Listing",
			CREATE_TITLE: "Create New Maintain Agent",
			DETAIL_TITLE: "Maintain Agent Detail",
			EDIT_TITLE: "Edit Maintain Agent",

			ADD: "Add",
			AGENT_ID: "Agent Id",
			AGENT_NAME: "Agent Name",
			URL: "Tracking url",
			COURIER_ID: "Courier ID",
			COUNTRY_ID: "Country",
			ROUTING: "Routing",
			SB_ROUTING: "SB Routing",
			SRW_ROUTING: "SRW Routing",
			NORMAL_GOODS_TEXT: "Normal Goods Text",
			SENSITIVE_GOODS: "Sensitive Goods"
		},
		SUPPLIER: {
			MENT_NAME: "厂家",
			LISTING_TITLE: "厂家列表",
			CREATE_TITLE: "创建厂家",
			DETAIL_TITLE: "厂家详情",
			EDIT_TITLE: "编辑厂家",
			CODE: "厂家编码",
			NAME: "厂家名称",

			CONTACT_NO: "联络号码",
			CONTACT_NAME: "联络负责人",
			OFFICE_NO: "办公号码",
			OFFICE_NAME: "办公负责人",

			BANKS: {
				ADD: "Add New Bank"
			}
		},
		DOC_TYPE: {
			LISTING_TITLE: "Doc Type Listing",
			CREATE_TITLE: "Create New Doc Type",
			DETAIL_TITLE: "Doc Type Detail",
			EDIT_TITLE: "Edit Doc Type Detail",

			LBL_DOC_TYPE_CODE: "Doc Type Code",
			LBL_DOC_TYPE_DESC: "Doc Type Desc",
			LBL__DOC_TYPE_NOTICE_TYPE: "Notice Type",
			LBL_POSTING_SIDE: "Posting Site",
			LBL_USE_SAME_ID: "Use Same ID",
			STATUS: "Status",

			MODULE_NAME: "Doc Type",

			COL_DOC_TYPE_CODE: "Doc Type Code",
			COL_DOC_TYPE_DESC: "Doc Type Desc",
			COL_POSTING_SIDE: "Posting Side",
			COL_USE_SAME_ID: "Use Same ID",

			SELECTION: {
				NO_POSTING: "No Posting",
				CREDIT: "Credit",
				DEBIT: "Debit"
			}
		},
		DOC_NUM: {
			LISTING_TITLE: "Doc Numbering Listing",
			CREATE_TITLE: "Create New Doc Numbering",
			DETAIL_TITLE: "Doc Numbering Detail",
			EDIT_TITLE: "Edit Doc Numbering Detail",

			LBL_NUM_DESC: "Doc Numbering Desc",
			LBL_DOC_TYPE: "Doc Type",
			LBL_WAREHOUSE: "Warehouse",
			LBL_NUM_BY_COMPANY_SUBGROUP: "By Company Sub Group?",
			LBL_COMPANY_SUBGROUP: "Company Sub Group",
			LBL_NUM_PREFIX: "Numbering Prefix",
			LBL_NUM_PREFIX_WITH_WAREHOUSE_COUNTRY: "Prefix with Warehouse Country?",
			LBL_NUM_POSTFIX: "Numbering Postfix",
			LBL_NUM_RESET: "Reset",
			LBL_NOTICE_TYPE: "Notice Type",
			LBL_NUM_DIGIT_COUNT: "Digit Count",
			LBL_NUM_LAST_NUM_INT: "Last Numbering (In Digit)",
			LBL_NUM_LAST_NUM_FORMAT: "Last Numbering (In Full)",
			LBL_AGENT: "Agent",

			MODULE_NAME: "Doc Numbering",

			COL_NUM_DESC: "Doc Numbering Desc",
			COL_DOC_TYPE: "Doc Type",
			COL_WAREHOUSE: "Warehouse",
			COL_NUM_BY_COMPANY_SUBGROUP: "By Company Sub Group ?",
			COL_COMPANY_SUBGROUP: "Company Sub Group",
			COL_NUM_PREFIX: "Numbering Prefix",
			COL_NUM_POSTFIX: "Numbering Postfix",
			COL_NUM_RESET: "Reset",
			COL_NUM_DIGIT_COUNT: "Digit Count",
			COL_NUM_LAST_NUM_INT: "Last Numbering (In Number)",
			COL_NUM_LAST_NUM_FORMAT: "Last Numbering (In Full)",

			SELECTION: {
				NO_RESET: "None",
				BY_YEAR: "By Year",
				BY_MONTH: "By Month"
			},

			DOC_NUM_FORMAT: "{prefix}{date}{number}{postfix}"
		},
		NOTICE_TYPE_GROUP: {
			LISTING_TITLE: "Notice Type Group Listing",
			CREATE_TITLE: "Create Notice Type Group",
			DETAIL_TITLE: "Notice Type Group Detail",
			EDIT_TITLE: "Edit Notice Type Group Detail",
			MODULE_NAME: "Notice Type Group",

			LBL_NOTICE_TYPE_GROUP_CODE: "Notice Type Group Code",
			LBL_NOTICE_TYPE_GROUP_DESC: "Notice Type Group Desc",
			LBL__DOC_TYPE_NOTICE_TYPE: "Notice Type",
			LBL_POSTING_SIDE: "Posting Site",
			LBL_USE_SAME_ID: "Use Same ID",

			COL_NOTICE_TYPE_GROUP_CODE: "Notice Type Group Code",
			COL_NOTICE_TYPE_GROUP_DESC: "Notice Type Group Desc",
			COL_POSTING_SIDE: "Posting Side",
			COL_USE_SAME_ID: "Use Same ID",
			COL_AGENT: "Agent",

			SELECTION: {
				NO_POSTING: "No Posting",
				CREDIT: "Credit",
				DEBIT: "Debit"
			}
		},
		CONTAINER: {
			COURIER_SERVICE: {
				MODULE_NAME: "Courier Service",
				LISTING_TITLE: "Courier Service Listing",
				CREATE_TITLE: "Create New Courier Service",
				DETAIL_TITLE: "Courier Service Detail",
				EDIT_TITLE: "Edit Courier Service",
				CODE: "Code",
				NAME: "Name",
				WAREHOUSE: "Warehouse"
			},
			DELIVERY_AGENT: {
				MODULE_NAME: "Delivery Agent",
				LISTING_TITLE: "Delivery Agent Listing",
				CREATE_TITLE: "Create New Delivery Agent",
				DETAIL_TITLE: "Delivery Agent Detail",
				EDIT_TITLE: "Edit Delivery Agent",
				CODE: "Code",
				NAME: "Name",
				SHIPMENT: "Shipment Type",
				WAREHOUSE: "Warehouse",
				LABEL: "Label",
				IS_DEFAULT: "Is Default?"
			},
			DELIVERY_AGENT_LINKAGE: "Delivery Agent Courier Service Linkage"
		}
	},
	SALESMAN_MANAGEMENT: {
		MAIN_SALESMAN: {
			CREATE_TITLE: "Create New Main Salesman",
			MODULE_NAME: "Main Salesman",
			EDIT_TITLE: "Edit Main Salesman",
			DETAIL_TITLE: "Main Salesman Detail",
			LISTING_TITLE: "Main Salesman Listing",

			LBL_MSM_CODE: "Main Salesman Code",
			LBL_MSM_NAME: "Main Salesman Name",
			LBL_COMPANY: "Company",

			LBL_EMAIL: "Email",
			LBL_JOINDATE: "Join Date",

			LBL_CONTACT_NUMBER: "Office Number",
			LBL_MOBILE_NUMBER: "Mobile Number",
			LBL_WECHATID: "Wechat ID",

			LBL_SALESMAN_COSTING_GROUP: "Salesman Costing Group",

			COL_GROUP_CODE: "Group Code",
			COL_GROUP_NAME: "Group Name",
			LBL_LOGIN_NAME: "Username",
			LBL_PASSWORD: "Password",
			LBL_CONFIRM_PASSWORD: "Confirm Password",
			COL_MSM_CODE: "Main Salesman Code",
			COL_MSM_NAME: "Main Salesman Name",
			BTN_GROUP_LIST: "Group List"
		},
		SALESMAN: {
			LISTING_TITLE: "Salesman Listing",
			DETAIL_TITLE: "Salesman Detail",
			EDIT_TITLE: "Edit Salesman Detail",
			CREATE_TITLE: "Create New Salesman",
			MODULE_NAME: "Salesman",

			LBL_SM_CODE: "Salesman Code",
			LBL_SM_NAME: "Salesman Name",

			COL_SM_CODE: "Salesman Code",
			COL_SM_NAME: "Salesman Name",
			COL_MSM_NAME: "Main Salesman Name",

			LBL_LOGIN_NAME: "Username",
			LBL_PASSWORD: "Password",
			LBL_CONFIRM_PASSWORD: "Confirm Password",
			LBL_EMAIL: "Email",
			LBL_JOINDATE: "Join Date",

			LBL_CONTACT_NO_CALLING_CODE: "Office Number Calling Code",
			LBL_CONTACT_NUMBER: "Office Number",
			LBL_MOBILE_NUMBER: "Mobile Number",
			LBL_MOBILE_NO_CALLING_CODE: "Mobile Number Calling Code",
			LBL_WECHATID: "Wechat ID",
			LBL_MAINSALESMAN: "Main Salesman",
			LBL_SALESMAN_COST: "Salesman Cost",

			COL_MAIN_SALESMAN: "Main Salesman",
			COL_SM_COST_GROUP: "Salesman Group Cost",
			COL_SM_MIN_COST_GROUP: "Salesman Group Min Cost"
		}
	},
	SETTINGS: {
		GENERAL: {
			TITLE: "General Settings",

			LBL_EMAIL_FROM: "Sender Email",
			LBL_WAREHOUSE: "Warehouse",
			MODULE_NAME: "General Settings",
			LBL_LOGIN_TITLE: "Login Title",
			LBL_SIDE_TITLE_IMAGE: "Side Title Image",
			LBL_META_TITLE: "Meta Title",
			TAB_TITLE: {
				GENERAL: "General"
			}
		},
		AGENT: {
			MENT_NAME: "Agent",
			LISTING_TITLE: "Agent Listing",
			CREATE_TITLE: "Create New Agent",
			DETAIL_TITLE: "Agent Detail",
			EDIT_TITLE: "Edit Agent Detail",
			NAME: "Agent Code",
			STATUS: "Status",
			APIURL: "API URL"
		},
		/**Start Language */
		LANGUAGE: {
			CREATE_TITLE: "新建语言",
			LISTING_TITLE: "语言列表",
			DETAIL_TITLE: "语言详情",
			EDIT_TITLE: "编辑语言",
			MODULE_NAME: "语言",
			LBL_CODE: "语言Code",
			LBL_NAME: "语言名",
			LBL_LOCALE: "Locale",
			LBL_SUPPORT_AT: "支持于",
			LBL_SORT_ORDER: "排序顺序",
			LBL_IMAGE: "图片",

			COL_CODE: "语言Code",
			COL_NAME: "语言名",
			COL_SUPPORT_AT: "支持于",
			COL_SORT_ORDER: "顺序",
			COL_FLAG: "旗帜"
		},
		/**End Language */
		// Payable Setting
		PAYABLE_SETTING: {
			LISTING_TITLE: "Payable Setting Listing",
			CREATE_TITLE: "Create New Payable Setting",
			EDIT_TITLE: "Edit Payable Setting",
			DETAIL_TITLE: "Payable Setting Detail",
			MODULE_NAME: "Payable Setting",
			LBL_CODE: "Payable Setting Code",
			LBL_NAME: "Payable Setting Name",
			LBL_PAYABLE_FORMAT_TYPE: "Payable Format Type",
			LBL_NOTICE_TYPE: "Notice Type",
			LBL_WAREHOUSE: "Warehouse",
			LBL_PAYABLE_FORMAT_TITLE: "Payable Format Title",
			LBL_MARKING_REMARK: "客户代号 Remark",
			LBL_IMPORTANT_NOTICE: "Important Notice",
			LBL_HEADER_REMARK: "Header Remark",
			LBL_FOOTER_REMARK: "Footer Remark",
			LBL_DESCRIPTION: "Description",
			LBL_LOCALE: "Locale",
			LBL_SUPPORT_AT: "Support At",
			LBL_SORT_ORDER: "Sort Order",
			LBL_IMAGE: "Image",
			LBL_SHIPMENT_FORMAT_TITLE: "Shipment Format Title",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_DELIVERY_ZONE: "Delivery Zone",

			COL_NAME: "Payable Setting Name",
			COL_NOTICE_TYPE: "Notice Type",
			COL_WAREHOUSE: "Warehouse",

			COL_PAYABLE_FORMAT_TITLE_NOTICE_TYPE: "Notice Type",
			COL_PAYABLE_FORMAT_TITLE_WAREHOUSE: "Warehouse",
			COL_PAYABLE_FORMAT_TITLE: "Title",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_DELIVERY_ZONE: "Delivery Zone",

			SUPPORT_AT_SELECTION: {
				ALL: "Admin Portal & Customer Portal",
				ADMIN: "Admin Portal Only",
				CUSTOMER: "Customer Portal Only"
			}
		},
		// End Payable Setting
		/**Start Warehouse Settings */
		WAREHOUSE_SETTINGS: {
			LISTING_TITLE: "Warehouse Settings",
			TITLE: "Warehouse Settings",

			COL_WAREHOUSE_CODE: "Warehouse Code",
			COL_STICKER_FEE: "Sticker Fee",
			COL_AGENT_ID: "Agent"
		}
		/**End Warehouse Settings */
	},
	CUSTOMER_MANAGEMENT: {
		CUSTOMER: {
			LISTING_TITLE: "Customer Listing",
			CREATE_TITLE: "Create New Customer",
			DETAIL_TITLE: "Customer Detail",
			EDIT_TITLE: "Edit Customer Detail",

			CREATE_CUSTOMER_MARKING_TITLE: "Create New Customer 客户代号",

			TITLE_CUSTOMER_LOGIN_INFO: "Login Information",
			TITLE_CUSTOMER_BASIC_INFO: "Basic Information",
			TITLE_CUSTOMER_BILLING_ADDRESS_INFO: "Billing Address Information",
			TITLE_CUSTOMER_COMPANY_INFO: "Company Information",

			LBL_CUSTOMER_SELECT_TYPE: "N",

			LBL_CUSTOMER_LOGIN_INFO_TYPE: "Customer Login",
			LBL_CUSTOMER_LOGIN_EXITING_INFO: "Customer Login Selection",

			LBL_CUSTOMER_MARKING: "客户代号",
			LBL_CUSTOMER_DEBTOR_ID: "Debtor Id",
			LBL_CUSTOMER_PARTNER_NAME: "Partner Name",
			LBL_CUSTOMER_NAME: "Partner Name",
			LBL_CUSTOMER_CUSTOMER_TYPE: "Customer Type",
			LBL_CUSTOMER_NRIC_OR_BRN: "NRIC / BRN",
			LBL_CUSTOMER_EMAIL: "Email",
			LBL_CUSTOMER_NICKNAME: "Login Nickname",
			LBL_CUSTOMER_MOBILE_NO: "Mobile No",
			LBL_CUSTOMER_PASSWORD: "Password",
			LBL_CUSTOMER_CONFIRM_PASSWORD: "Confirm Password",
			LBL_CUSTOMER_CONTROL_ACCOUNT: "Control Account",
			LBL_CUSTOMER_MAINSALESMAN: "Main Salesman",
			LBL_CUSTOMER_SALESMAN: "Salesman",
			LBL_CUSTOMER_COMPANY: "Company",
			LBL_CUSTOMER_COMPANY_GROUP: "Company Group",
			LBL_CUSTOMER_CURRENCY: "Currency",
			LBL_CUSTOMER_CREDIT_TERM: "Credit Term",
			LBL_CUSTOMER_NOTICE_TYPE: "Notice Type",
			LBL_CUSTOMER_CREDIT_TERM_DAYS: "Term Days{terms_name}",
			LBL_CUSTOMER_IS_DEFAULT: "Is Default",
			LBL_CUSTOMER_IS_VERIFIED: "Is Verified",

			LBL_CUSTOMER_GST_NO: "GST No",
			LBL_CUSTOMER_GST_VERIFY_DATE: "GST Verify Date",
			LBL_CUSTOMER_GST_USE_NONE_DEFAULT_CODE: "Use None Default GST Code",
			CHECKBOX_USE_NONE_DEFAULT: "Use None Default",
			LBL_CUSTOMER_DEFAULT_INVOICE_FORMAT: "Default Invoice Format",
			LBL_SPECIAL_RATE: "Special Rate #",
			LBL_SPECIAL_RATE_REMARK: "Special Rate",
			LBL_SM_FINANCE_RATE: "Salesman Finance Rate",
			LBL_FINANCE_RATE: "Finance Rate",
			LBL_USE_CUSTOMER_DO_HEADER: "Use Customer Do Header",

			LBL_CUSTOMER_USERNAME: "Email",
			LBL_CUSTOMER_RE_PASSWORD: "Confirm Password",
			LBL_CUSTOMER_OVERDUE_LIMIT: "Overdue Limit",
			LBL_CUSTOMER_BILLING_ADDRESS: "Billing Address",
			LBL_CUSTOMER_CONTACT_PERSON_NAME: "Contact Person Name",
			LBL_CUSTOMER_CONTACT_EMAIL: "Contact Email",
			LBL_CUSTOMER_CONTACT_MOBILE_NO: "Contact Mobile No",
			LBL_CUSTOMER_CONTACT_OFFICE_NO: "Contact Office No",
			LBL_CUSTOMER_CONTACT_FAX_NO: "Contact Fax No",
			LBL_CUSTOMER_COMPANY_NAME: "Company Name",
			LBL_CUSTOMER_COMPANY_WEBSITE: "Company Website",
			LBL_CUSTOMER_TAX_TYPE: "Tax Type",
			LBL_CUSTOMER_TAX: "Tax",
			LBL_CUSTOMER_CTOS_NO: "CTOS Account",
			LBL_CUSTOMER_CTOS_DATE: "CTOS Date",

			LBL_CUSTOMER_BILLING_ADD: "Billing Address",
			LBL_CUSTOMER_BILLING_ADD_DESCRIPTION: "Address Description",
			LBL_CUSTOMER_BILLING_ADD_REMARK: "Attention",
			LBL_CUSTOMER_BILLING_ADD_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_BILLING_ADD_ADDRESS: "Billing Address",
			LBL_CUSTOMER_BILLING_ADD_COUNTRY: "Country",
			LBL_CUSTOMER_BILLING_ADD_STATE: "State",
			LBL_CUSTOMER_BILLING_ADD_CITY: "City",
			LBL_CUSTOMER_BILLING_ADD_OTHER_CITY: "Other City",
			LBL_CUSTOMER_BILLING_ADD_POSTCODE: "Postcode",
			LBL_CUSTOMER_BILLING_ADD_OTHER_POSTCODE: "Other Postcode",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_CONTACT_PIC_NAME: "Mobile Contact PIC Name",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_CONTACT_NO: "Mobile Contact No",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_ALT_CONTACT_PIC_NAME: "Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_ALT_CONTACT_NO: "Mobile Alt Contact No",
			LBL_CUSTOMER_BILLING_ADD_OFFICE_TEL_PIC_NAME: "Office Tel PIC Name",
			LBL_CUSTOMER_BILLING_ADD_OFFICE_TEL_NO: "Office Tel No",
			LBL_CUSTOMER_BILLING_ADD_WEBSITE: "Website",
			LBL_CUSTOMER_BILLING_ADD_EMAIL: "Email",
			LBL_CUSTOMER_BILLING_ADD_ALT_EMAIL: "Alt Email",

			LBL_CUSTOMER_MARKING_BILLING_ADD: "Billing Address",
			LBL_CUSTOMER_MARKING_BILLING_ADD_DESCRIPTION: "Address Description",
			LBL_CUSTOMER_MARKING_BILLING_ADD_REMARK: "Attention",
			LBL_CUSTOMER_MARKING_BILLING_ADD_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_ADDRESS: "Billing Address",
			LBL_CUSTOMER_MARKING_BILLING_ADD_COUNTRY: "Country",
			LBL_CUSTOMER_MARKING_BILLING_ADD_STATE: "State",
			LBL_CUSTOMER_MARKING_BILLING_ADD_CITY: "City",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OTHER_CITY: "Other City",
			LBL_CUSTOMER_MARKING_BILLING_ADD_POSTCODE: "Postcode",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OTHER_POSTCODE: "Other Postcode",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_CONTACT_PIC_NAME: "Mobile Contact PIC Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_CONTACT_NO: "Mobile Contact No",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_ALT_CONTACT_PIC_NAME: "Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_ALT_CONTACT_NO: "Mobile Alt Contact No",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OFFICE_TEL_PIC_NAME: "Office Tel PIC Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OFFICE_TEL_NO: "Office Tel No",
			LBL_CUSTOMER_MARKING_BILLING_ADD_WEBSITE: "Website",
			LBL_CUSTOMER_MARKING_BILLING_ADD_EMAIL: "Email",
			LBL_CUSTOMER_MARKING_BILLING_ADD_ALT_EMAIL: "Alt Email",
			LBL_CUSTOMER_MARKING_USE_BILLING_MARKING: "Use 客户代号 Billing?",

			LBL_CUSTOMER_PREFERENCE_TERRITORY: "Territory",
			LBL_CUSTOMER_PREFERENCE_INDUSTRIES: "Industries",
			LBL_CUSTOMER_PREFERENCE_PARTNER_TYPE: "Partner Type",
			LBL_CUSTOMER_PREFERENCE_GL_SALES_ACCOUNT: "GL Sales Account",
			LBL_CUSTOMER_PREFERENCE_STATEMENT_TYPE: "Statement Type",

			LBL_CUSTOMER_CREDIT_CONTROL_CREDIT_LIMIT: "Credit Limit",
			LBL_CUSTOMER_CREDIT_CONTROL_CREDIT_LIMIT_OVER_ACTION_TYPE: "Action On Credit Limit was over",
			LBL_CUSTOMER_CREDIT_CONTROL_COMBINE_LIMIT: "Combine Limit",
			LBL_CUSTOMER_CREDIT_CONTROL_COMBINE_LIMIT_OVER_ACTION_TYPE: "Action On Combine Limit was over",

			LBL_CUSTOMER_CREDIT_CONTROL_OVERDUE_DAYS: "Overdue Days",
			LBL_CUSTOMER_CREDIT_CONTROL_OVERDUE_LIMIT: "Overdue Limit",
			LBL_CUSTOMER_CREDIT_CONTROL_OVERDUE_LIMIT_OVER_ACTION_TYPE: "Action On Overdue Limit was over",
			LBL_CUSTOMER_CREDIT_CONTROL_TOTAL_OVERDUE_LIMIT: "Total Overdue Limit",
			LBL_CUSTOMER_CREDIT_CONTROL_TOTAL_OVERDUE_LIMIT_OVER_ACTION_TYPE: "Action On Total Overdue Limit was over",
			LBL_CUSTOMER_CREDIT_CONTROL_TOTAL_OVERDUE_DAYS: "Total Overdue Days",

			LBL_CUSTOMER_NOTE_INVOICE_NOTE: "Invoice Note",
			LBL_CUSTOMER_NOTE_AR_NOTE: "AR Note",

			LBL_CUSTOMER_TRANSACTION_DESC_INVOICE_DESC: "Invoice Description",
			LBL_CUSTOMER_TRANSACTION_DESC_DN_DESC: "DN Description",
			LBL_CUSTOMER_TRANSACTION_DESC_CN_DESC: "CN Description",
			LBL_CUSTOMER_TRANSACTION_DESC_PAYMENT_DESC: "Transaction Description",

			LBL_CUSTOMER_INTEREST_PENALTY_PA_TITLE: "PA (Purchase)",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_MODE: "PA Penalty Mode",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_RATE: "PA Penalty Rate",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_TERMS_DAY: "PA Penalty Terms Day",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_ALLOW_DAY: "PA Penalty Allow Day",

			LBL_CUSTOMER_INTEREST_PENALTY_SC_TITLE: "SC (Service Charge)",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_MODE: "SC Penalty Mode",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_RATE: "SC Penalty Rate",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_TERMS_DAY: "SC Penalty Terms Day",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_ALLOW_DAY: "SC Penalty Allow Day",

			LBL_CUSTOMER_INTEREST_PENALTY_TA_TITLE: "TA (TAX)",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_MODE: "TA Penalty Mode",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_RATE: "TA Penalty Rate",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_TERMS_DAY: "TA Penalty Terms Day",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_ALLOW_DAY: "TA Penalty Allow Day",

			LBL_CUSTOMER_CTOS_CTOS_DATE: "CTOS Date",
			LBL_CUSTOMER_CTOS_CTOS_ACCOUNT: "CTOS ACC",
			LBL_CUSTOMER_CTOS_MONITORING_DATE: "Monitoring Date",
			LBL_CUSTOMER_CTOS_ETR_SUBMISSION_DATE: "eTR Submission Date",

			TAB_TITLE_ADDRESS: "Address",
			TAB_TITLE_CONTACT: "Contact",
			TAB_TITLE_MARKING_BILLING: "客户代号 Billing",
			TAB_TITLE_PREFERENCE: "Preference",
			TAB_TITLE_CREDIT_CONTROL: "Credit Control",
			TAB_TITLE_NOTE: "Note",
			TAB_TITLE_TRANSACTION_DESC: "Transaction Desc",
			TAB_TITLE_INTEREST_PENALTY: "Interest Penalty",
			TAB_TITLE_CTOS: "CTOS",
			TAB_TITLE_NEW_MARKING: "New 客户代号",

			MODULE_NAME: "Customer",
			CUSTOMER_MARKING_MODULE_NAME: "Customer 客户代号",

			BTN_CREATE_NEW_CUSTOMER_MARKING: "New 客户代号",
			BTN_SAVE_NEW_CUSTOMER_MARKING: "Save New 客户代号",

			COL_AGENT: "Agent",
			COL_COMPANY: "Company",
			COL_MAINSALESMAN: "Main Salesman",
			COL_SALESMAN: "Salesman",
			COL_NAME: "Name",
			COL_EMAIL: "Email",
			COL_MARKING: "客户代号",
			COL_MARKING_STATUS: "客户代号 Status",
			COL_FULL_MARKING: "Full 客户代号",
			COL_NOTICE_TYPE: "Notice Type",

			DESC_CUSTOMER_MARKING_BILLING_ADD_ALT_EMAIL: "Able to enter multiple email by semicolon(;), etc:gim_admin@gim.com;gim_admin2@gim.com;gim_admin3@gim.com",

			MESSAGE_SWITCH_NEW_MARKING_TAB: "Current marking info changes will lose. (Ignore if you didn't make any changes or saved)",

			CUSTOMER_EMAIL_EXISTS: "Customer Login email exists",
			CUSTOMER_NAME_EXISTS: "Partner Name exists",
			NRICORBRN_EXISTS: "Nric / Brn exists",
			CUSTOMER_MARKING_UNIQUE_ERROR: "客户代号 must be unique for same company group"
		},
		DELIVERY_ADDRESS: {
			LISTING_TITLE: "Delivery Address Listing",
			CREATE_TITLE: "Create New Delivery Address",
			DETAIL_TITLE: "Delivery Address Detail",
			EDIT_TITLE: "Edit Delivery Address Detail",
			MODULE_NAME: "Delivery Address",

			TITLE_DELIVERY_ADDRESS_BASIC_INFO: "Basic Information",
			TITLE_DELIVERY_ADDRESS_ADDRESS_AND_INFO: "Delivery Address & Contact Info",
			TITLE_DELIVERY_ADDRESS_SETTINGS: "Settings",

			LBL_DELIVERY_ADDRESS_DELIVERY_ID: "Delivery Address Id",
			LBL_DELIVERY_ADDRESS_DELIVERY_ADDRESS_DESCRIPTION: "Name/Company name",
			LBL_DELIVERY_ADDRESS_DEFAULT_BILLING: "Default Billing Address ?",
			LBL_DELIVERY_ADDRESS_MARKING: "客户代号",
			LBL_DELIVERY_ADDRESS_CUSTOMER: "Customer",
			LBL_DELIVERY_ADDRESS_PAYMENT_TYPE: "Payment Type",
			LBL_DELIVERY_ADDRESS_NOTICE_TYPE_ID: "Notice Type ID",
			LBL_DELIVERY_ADDRESS_PRINT_ADDRESS_IN_INVOICE: "打印 Address In Invoice",
			LBL_DELIVERY_ADDRESS_PRINT_DELIVERY_ADDRESS: "打印 Delivery Address",
			LBL_DELIVERY_ADDRESS_CONTACT_NAME: "Contact Name",
			LBL_DELIVERY_ADDRESS_CONTACT_EMAIL: "Email",
			LBL_DELIVERY_ADDRESS_CONTACT_WEBSITE: "Contact Website",
			LBL_DELIVERY_ADDRESS_BUILDING_NAME: "Building Name",
			LBL_DELIVERY_ADDRESS_ADDRESS: "Address",
			LBL_DELIVERY_ADDRESS_POSTCODE: "Postcode",
			LBL_DELIVERY_ADDRESS_OTHER_POSTCODE: "Other Postcode",
			LBL_DELIVERY_ADDRESS_CITY: "City",
			LBL_DELIVERY_ADDRESS_OTHER_CITY: "Other City",
			LBL_DELIVERY_ADDRESS_STATE: "State",
			LBL_DELIVERY_ADDRESS_COUNTRY: "Country",
			LBL_DELIVERY_ADDRESS_TEL_NO: "Office Contact No",
			LBL_DELIVERY_ADDRESS_TEL_PIC: "Office Contact Person In Charge",
			LBL_DELIVERY_ADDRESS_TEL_NO_WITH_PIC: "Tel No + PIC",
			LBL_DELIVERY_ADDRESS_MOBILE_NO: "Mobile Contact No",
			LBL_DELIVERY_ADDRESS_MOBILE_PIC: "PIC (MOBILE)",
			LBL_DELIVERY_ADDRESS_MOBILE_NO_WITH_PIC: "Mobile No + PIC",
			LBL_DELIVERY_ADDRESS_FAX_NO: "Fax No",
			LBL_DELIVERY_ADDRESS_RECEIVING_HOUR: "Receiving Hour",
			LBL_DELIVERY_ADDRESS_REMARK_ID: "Delivery Remark",
			LBL_DELIVERY_ADDRESS_REMARK: "Remark",
			LBL_DELIVERY_ADDRESS_USE_CUSTOMER_DO: "Use Customer DO ?",
			LBL_DELIVERY_ADDRESS_CUSTOMER_DO_HEADER_ID: "Customer Do Header ID",
			LBL_DELIVERY_ADDRESS_RESHIP: "Reship?",
			LBL_DELIVERY_ADDRESS_EXP_FORWARDER_ID: "Exp Forwarder ID",
			LBL_DELIVERY_ADDRESS_FORWARDER_COSTING_TYPE: "Forwarder Costing Type",
			LBL_DELIVERY_ADDRESS_AUTHORISED_LETTER: "Authorised Letter ?",
			LBL_DELIVERY_ADDRESS_HAS_ESYSTEM_ID: "Has E-System ID ?",
			LBL_DELIVERY_ADDRESS_GO_UPSTAIRS: "Go Upstairs ?",
			LBL_DELIVERY_ADDRESS_UPSTAIRS_COSTING_TYPE: "U/P Costing Type",
			LBL_DELIVERY_ADDRESS_FOLLOW_DELIVERY_ID_DUE_DAY: "Follow Delivery ID Due Day ?",
			LBL_DELIVERY_ADDRESS_DUE_DAYS: "Due Days",
			LBL_DELIVERY_ADDRESS_BY_AIR_SPECIAL_BILLING: "By Air Special Billing ?",
			LBL_DELIVERY_ADDRESS_BRN: "NRIC / BRN",
			LBL_DELIVERY_ADDRESS_MOBILE_NO_ALT: "Mobile Contact No (Alt)",
			LBL_DELIVERY_ADDRESS_MOBILE_NO_ALT_PIC: "Mobile Contact (Alt) Person In Charge",
			LBL_DELIVERY_ADDRESS_CUSTOMER_NAME: "Customer Name",
			LBL_DELIVERY_ADDRESS_RECEIVING_DAY: "Receiving Day",
			LBL_DELIVERY_ADDRESS_RECEIVING_REMARK: "Receiving Time Remark",

			COL_DELIVERY_ID: "Delivery ID",
			COL_CUSTOMER_NAME: "Customer Name",
			COL_MARKING: "客户代号",
			COL_CODE: "Code",
			COL_NAME: "Name",
			COL_COMPANY_NAME: "Delivery Address Description / Company Name",
			COL_COMPANY_GROUP: "Company Group",
			COL_MSM_NAME: "Main Salesman",
			COL_SM_NAME: "Salesman",
			COL_EMAIL: "Email",
			COL_CONTACT_NO: "Mobile Contact No",
			COL_MOBILE_PIC: "Person In Charge",
			COL_UPSTAIRS: "Upstairs?",
			COL_COUNTRY: "Country",
			COL_STATE: "State",
			COL_CITY: "City",
			COL_POSTCODE: "Postcode",
			COL_DELIVERY_ADDRESS: "Delivery Address",

			PH_RECEIVING_HOUR_FROM: "-- Receiving Hour From --",
			PH_RECEIVING_HOUR_TO: "-- Receiving Hour To --",

			PH_RECEIVING_DAY_FROM: "-- Receiving Day From --",
			PH_RECEIVING_DAY_TO: "-- Receiving Day To --",

			DELIVERY_ID_VALIDATION: {
				DELIVERY_ID_LENGTH: "Must be {min} to {max} character.",
				DELIVERY_ID_SPECIAL_CHARACTER: "Allow {len} special character({special_character}) character only/Any alphanumeric character.",
				DELIVERY_ID_UNIQUE: "Delivery ID is available"
			},

			MSG_MARKING_ID_SAVE_AS_DELIVERY_ID: "Delivery Id will be assign after 1st marking id generated.",
			MSG_CANNOT_EDIT__DELIVERY_ID_MARKING: "Delivery Id assigned with 1st marking id generated. You not allow to edit",
			MSG_BLOCK_UPDATE_DELIVERY_ID: "Delivery Id used in Quotation/EPO, you are not allow to edit",
			MSG_BLOCK_UPDATE_COUNRTY_STATE_ID: "Delivery Address used in Quotation/EPO, you are not allow to edit country/state."
		},
		CUSTOMER_DO_HEADER: {
			LISTING_TITLE: "Customer Do Header Listing",
			CREATE_TITLE: "Create New Customer Do Header",
			DETAIL_TITLE: "Customer Do Header Detail",
			EDIT_TITLE: "Edit Customer Do Header Detail",

			TITLE_CUSTOMER_DO_HEADER_BASIC_INFO: "Basic Information",
			TITLE_CUSTOMER_DO_HEADER_ADDRESS_AND_INFO: "Address & Contact",

			LBL_CUSTOMER_DO_HEADER_CODE: "Do Header Code",
			LBL_CUSTOMER_DO_HEADER_NAME: "Customer Name",
			LBL_CUSTOMER_DO_HEADER_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_DO_HEADER_ADDRESS: "Address",
			LBL_CUSTOMER_DO_HEADER_POSTCODE: "Postcode",
			LBL_CUSTOMER_DO_HEADER_CITY: "City",
			LBL_CUSTOMER_DO_HEADER_STATE: "State",
			LBL_CUSTOMER_DO_HEADER_COUNTRY: "Country",
			LBL_CUSTOMER_DO_HEADER_FAX_NO: "Fax No",
			LBL_CUSTOMER_DO_HEADER_WEBSITE: "Website",
			LBL_CUSTOMER_DO_HEADER_DO_NUMBER_FORMAT: "Do Number Format",
			LBL_CUSTOMER_DO_HEADER_REMARK: "Remark",
			LBL_CUSTOMER_DO_HEADER_CONTACT_EMAIL: "Email",
			LBL_CUSTOMER_DO_HEADER_MOBILE_NO: "Mobile Contact No",
			LBL_CUSTOMER_DO_HEADER_CONTACT_NAME: "Mobile Contact PIC Name",
			LBL_CUSTOMER_DO_HEADER_MOBILE_NO_ALT: "Mobile Alt Contact No",
			LBL_CUSTOMER_DO_HEADER_MOBILE_NO_ALT_PIC: "Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_DO_HEADER_TEL_PIC: "Office Tel PIC Name",
			LBL_CUSTOMER_DO_HEADER_TEL_NO: "Office Tel No",
			LBL_CUSTOMER_DO_HEADER_CONTACT_PIC: "Contact Pic",
			LBL_CUSTOMER_DO_HEADER_CONTACT_NO: "Contact No",
			LBL_CUSTOMER_DO_HEADER_CONTACT_WEBSITE: "Website",

			CUSTOMER_DO_HEADER_CODE_EXISTS: "Do Header Code Exists",

			MODULE_NAME: "Customer Do Header",

			COL_CODE: "Do Header Code",
			COL_NAME: "Customer Name",
			COL_CUSTOMER_EMAIL: "Customer Email",
			COL_DO_NUMBER_FORMAT: "DO Number Format",
			COL_COUNTRY: "Country",
			COL_STATES: "States",
			SELECTION: {
				DOC_NUM_A: "Doc Num A",
				DOC_NUM_B: "Doc Num B"
			}
		}
	},
	USER_N_ROLE_MANAGEMENT: {
		USER: {
			LISTING_TITLE: "User Listing",
			CREATE_TITLE: "Create New User",
			DETAIL_TITLE: "User Detail",
			EDIT_TITLE: "Edit User Detail",

			LBL_USER_NAME: "Name",
			LBL_USER_USERNAME: "Username",
			LBL_USER_EMAIL: "Email",
			LBL_USER_PASSWORD: "Password",
			LBL_USER_ROLE: "Role",
			LBL_USER_DATA_FILTER: "Data Filter",
			LBL_USER_IGNORE_FILTER: "Ignore Filter",

			MODULE_NAME: "User",

			COL_USERNAME: "Username",
			COL_NAME: "Name",
			COL_LAST_LOGIN: "Last Login",
			COL_ROLES: "ROLES",
			COL_DATA_FILTER: "Data Filter (s)",
			COL_IGNORE_FILTER: "Ignore Data Filter"
		},
		ROLE: {
			LISTING_TITLE: "Role Listing",
			CREATE_TITLE: "Create New Role",
			DETAIL_TITLE: "Role Detail",
			EDIT_TITLE: "Edit Role Detail",

			LBL_ROLE_NAME: "Role Name",
			LBL_ROLE_DESCRIPTION: "Role Description",
			LBL_ROLE_PERMISSION_PRIVILEGE: "Permission & Privilege",
			LBL_ROLE_PERMISSION: "Role Permission",
			LBL_DEFAULT_SORT: "Default Sort",
			LBL_SORT_ALPHABET: "Sort by Alphabet",

			MODULE_NAME: "Role",

			COL_NAME: "Role Name"
		},
		DATA_FILTER: {
			LISTING_TITLE: "Data Filter Listing",
			CREATE_TITLE: "Create New Data Filter",
			DETAIL_TITLE: "Data Filter Detail",
			EDIT_TITLE: "Edit Data Filter Detail",

			LBL_DATA_FILTER_NAME: "Name",
			LBL_DATA_FILTER_DESCRIPTION: "Description",
			LBL_DATA_FILTER_COMPANY: "Company",
			LBL_DATA_FILTER_WAREHOUSE: "Warehouse",
			LBL_DATA_FILTER_MSM: "Main Salesman",
			LBL_DATA_FILTER_SM: "Salesman",
			LBL_DATA_FILTER_COUNTRY: "Country",
			LBL_DATA_FILTER_AGENT: "Agent",
			LBL_DATA_FILTER_MARKING: "客户代号",
			LBL_DATA_FILTER_PERMISSION_PRIVILEGE: "Permission & Privilege",
			LBL_INCLUDE: "include",
			LBL_EXCLUDE: "exclude",

			MODULE_NAME: "Data Filter",

			COL_NAME: "Data Filter Name"
		}
	},
	LOAD_CONTAINER: {
		CONTAINER: {
			MENT_NAME: "Ref Id Details",
			LISTING_TITLE: "Ref Id Listing",
			CREATE_TITLE: "Create New Ref Id",
			DETAIL_TITLE: "Ref Id Detail",
			EDIT_TITLE: "Edit Ref Id Detail",
			DATE: "Date",
			PH_ENTER_INQUIRY_DATE_FROM: "输入 日期从",
			PH_ENTER_INQUIRY_DATE_TO: "输入 日期至",

			COUNTRY: "Destination Country",
			ORIGIN_COUNTRY: "Origin Country",
			LOADED_DATE: "Loaded Date",
			REF_ID: "Ref ID",
			SHIPMENT_MODE: "Shipment Mode",
			STANDARD: "Standard",
			S_TYPES: "Shipment Type",
			S_TERMS: "Shipment Term",
			DELIVERY_ZONE: "Delivery Zone",
			ED: "ED",
			EA: "EA",
			LPT: "LPT",
			DP: "DP",
			TTL_M: "TTL M",
			TTL_C: "TTL C",
			TTL_W: "TTL W",
			LNO: "LNo",
			REMARKS: "Remarks",
			RUNNING_NO_FORMAT: "Running No. Format",
			DIRECT: "Direct",
			CT_NO: "CT NO.",
			CT_TYPE: "CT TYPE",
			MARKING: "MARKING",
			CT_DESC: "CT Desc",
			RG_ID: "RG ID",
			FR_ID: "FR ID",
			SID: "SID",
			SHP_NA: "SHP NA",
			SL_ID: "SL ID",
			CID: "CID",
			FID: "FID",
			VSL_NO: "VSL NO.",
			VSL_NA: "VSL NA",
			FT_DET: "FT-DET",
			FT_DEM: "FT-DEM",
			BL_DATE: "BL DATE",
			L_DATE: "L. DATE",
			CY_DATE: "CY DATE",
			ED_DATE: "ED DATE",
			EA_DATE: "EA DATE",
			TOTAL_CTNS: "TTL C",
			TOTAL_M3: "TTL M",
			TOTAL_G_W: "TTL G",
			TOTAL_N_W: "TOTAL N W",
			TOTAL_AMT: "TOTAL AMT",
			L_BY: "L BY",
			DPT: "DPT",
			DELI_CITY: "DELI CITY",
			SNO1: "SNO.1",
			SNO2: "SNO.2",
			BL_NO: "BL NO",
			TLX_NO: "TLX NO.",
			FAD: "FAD",
			FDD: "FDD",
			ADD: "ADD",
			AAD: "AAD",
			PICK_DATE: "PICK DATE",
			LK_NO: "LK NO.",
			TRS_HP: "TRS HP",
			TRUCK1: "TRUCK 1",
			TRUCK2: "TRUCK 2"
		},
		LOADING: {
			MENT_NAME: "Loading",
			LISTING_TITLE: "Loading Listing",
			CREATE_TITLE: "Create New Loading",
			DETAIL_TITLE: "Container Loading",
			EDIT_TITLE: "Edit Loading",
			DATE: "Date",
			PH_ENTER_INQUIRY_DATE_FROM: "输入 日期从",
			PH_ENTER_INQUIRY_DATE_TO: "输入 日期至",

			REF_ID: "Ref Id",
			LOADED_DATE: "Loaded Date",
			STATUS: "Status",
			TOTAL_ITEM: "Items",
			CTN: "CTN",
			KG: "Kg",
			M3: "M3",
			LOADED_AMOUNT: "Load Amount",
			LOADED_QTY: "Load QTY",
			DISCOUNT: "Discount",
			DEPOSIT: "Deposit",
			SUPPLIER_DEPOSIT: "Supplier Deposit",
			FCP_AMOUNT: "FCP Amount",
			MY_PAID_AMOUNT: "My Paid Amount",
			CASH_PAYMENT: "Cash Payment",
			ADD_FROM_SO_RECEIVED: "Add from SO Received",
			ADD: "Add",
			TOTAL_QUANTITY: "Total Quantity",
			TOTAL_AMOUNT: "Total Amount",

			DETAILS: {
				REF_NO: "Reference No",
				TOTAL_CTN: "Total Ctn",
				TOTAL_M3: "Total M3",
				ADD: "Add",
				MARKING: "客户代号",
				DELIVERY_ID: "Delivery ID",
				STOCK_ID: "Stock ID",
				LINE: "Line",
				SO_NO: "SO NO",
				STOCK_DESC1: "Stock Desc 1",
				STOCK_DESC2: "Stock Desc 2",
				UOM: "Uom",
				LOADED_QTY: "Loaded Qty",
				LOAD_QTY: "Load Qty",
				RECEIVE_QTY: "Receive Qty",
				BALANCE_QTY: "Balance Qty",
				QTY_PER_PACK: "Qty Per Pack",
				BUYING_PRICE: "Buying Price",
				LOAD_AMOUNT: "Load Amount",
				LOADED_AMT: "Loaded Amount",
				RECEIVE_AMOUNT: "Receive Amount",
				BALANCE_AMT: "Balance Amount",
				CTN: "Ctn",
				KG: "Kg",
				M3: "M3",
				WH_DISCOUNT: "Warehouse Discount",
				DISCOUNT: "Discount",
				CASH_PAYMENT: "Cash Payment",
				DEPOSIT: "Deposit",
				SUPPLIER_DEPOSIT: "Supplier Deposit",
				MY_PAID: "My Paid",
				FCP_AMOUNT: "Fcp Amount",
				DCP_AMOUNT: "Dcp Amount",
				RECEIVE_DATE: "Receive Date",
				CTN_NO: "CTN No",
				ITEM_CODE: "Item Code",
				RECEIVE: "Receive",
				REC_BAL: "Loaded QTy",
				LOADED: "Load",
				WAREHOUSE_LOCATION: "Warehouse Location",
				MARKING: "客户代号",
				VOL_WEIGHT: "Volume Weight"
			},
			MSG: {
				SO_FULL_LOADED: "S/O Fully Loaded OR Not Found.",
				CONTAINS_DO: "Part of Loaded S/O was created D/O. You are not allow to delete."
			}
		},
		CLOSE: {
			MENT_NAME: "Close Receiving & Loading",
			LISTING_TITLE: "Close Receiving & Loading Listing",
			CREATE_TITLE: "Create New Close Receiving & Loading",
			DETAIL_TITLE: "Close Receiving & Loading",
			EDIT_TITLE: "Edit Close Receiving & Loading",
			DATE: "Date",
			PH_ENTER_INQUIRY_DATE_FROM: "输入 Loaded 日期从",
			PH_ENTER_INQUIRY_DATE_TO: "输入 Loaded 日期至",
			S_TYPES: "S. Type",

			REF_ID: "Ref Id",
			LOADED_DATE: "Loaded Date",
			STATUS: "Status",
			OPEN: "Open",
			ADD_FROM_SO_RECEIVED: "Add from SO Received"
		},
		OPEN: {
			MENT_NAME: "Reopen Receiving & Loading",
			LISTING_TITLE: "Reopen Receiving & Loading Listing",
			CREATE_TITLE: "Create New Reopen Receiving & Loading",
			DETAIL_TITLE: "Reopen Receiving & Loading",
			EDIT_TITLE: "Edit Reopen Receiving & Loading",
			DATE: "Date",
			PH_ENTER_INQUIRY_DATE_FROM: "输入 Loaded 日期从",
			PH_ENTER_INQUIRY_DATE_TO: "输入 Loaded 日期至",
			S_TYPES: "S. Type",

			REF_ID: "Ref Id",
			LOADED_DATE: "Loaded Date",
			STATUS: "Status",
			OPEN: "Open",
			ADD_FROM_SO_RECEIVED: "Add from SO Received"
		},
		REOPEN_SO: {
			MENT_NAME: "ReOpen S/O for Update",
			LISTING_TITLE: "ReOpen S/O for Update Listing",
			CREATE_TITLE: "Create New ReOpen S/O for Update",
			DETAIL_TITLE: "ReOpen S/O for Update",
			EDIT_TITLE: "Edit ReOpen S/O for Update",

			REF_ID: "Ref Id",
			SO_NO: "SO No",
			RECEIVE_DATE: "Receive Date",
			REOPEN_DATE: "ReOpen Date",
			OPEN: "Open?",
			PLEASE_SELECT_DATA: "Please select data"
		},
		CLOSE_REOPEN_SO: {
			MENT_NAME: "Close ReOpen S/O",
			LISTING_TITLE: "Close ReOpen S/O Listing",
			CREATE_TITLE: "Create New Close ReOpen S/O",
			DETAIL_TITLE: "Close ReOpen S/O",
			EDIT_TITLE: "Edit Close ReOpen S/O",

			REF_ID: "Ref Id",
			SO_NO: "SO No",
			RECEIVE_DATE: "Receive Date",
			REOPEN_DATE: "ReOpen Date",
			OPEN: "Open?",
			CLOSE: "Close",
			PLEASE_SELECT_DATA: "Please select data"
		}
	},
	PAYABLES: {
		LISTING_TITLE: "Payable Listing",
		CREATE_TITLE: "Create Payable Notes",
		VIEW_TITLE: "View Payable Notes",
		EDIT_TITLE: "Edit Payable Notes",
		MODAL_TITLE: "Add Orders",
		PRINT_MODAL_TITLE: "打印 Payable Notes / GRN Receipt",
		PRINT: "打印",
		MODULE_NAME: "Payable Notes",
		PAYMENT_STATUS: "Deposit Status",
		Q_NUMBER: "Q Number",
		REFERENCE_NO: "Payable Notes No.",
		SUPPLIER_CONTACT_NO: "Supplier Contact No.",
		DRIVER_NAME: "Driver Name",
		DRIVER_CONTACT_NO: "Driver Contact No.",
		SO_NO: "S/O单号",
		RESOLVE_STATUS: "Resolve Status",
		RECEIVE_DATE: "Receive Date",
		GENERATE: "Generate",
		NOTICE_TYPE: "Notice Type",
		SUPPLIER_NAME: "Supplier Name",
		AMOUNT: "Amount",
		PAY_AMOUNT: "Pay Amount",
		REMARK: "Remark",
		RESOLVE_STATUS_OPTION: {
			NORMAL: "Normal",
			RESOLVE_REQ: "Resolve Required",
			RESOLVED: "Resolved/Paid"
		},
		CUSTOMER_NAME: "Customer Name",
		MARKING: "客户代号",
		MAIN_SALESMAN: "Main Salesman",
		SALESMAN: "Salesman",
		COMPANY: "Company",
		COMPANY_GROUP: "Company Group",
		AGENT: "Agent",
		ISSUE_DATE: "Issue Date",
		PH_ENTER_DATE_FROM: "From",
		PH_ENTER_DATE_TO: "To",
		SUPPLIER_CONTACT: "Supplier Contact",
		RECEIVE_AMOUNT: "Receive Amount",
		PAYABLE_AMOUNT: "Payable Amount",
		CREATED_BY: "Created By",
		CREATED_ON: "Payment Date",
		SO_NUMBER: "So Number",
		SUPPLIER_DEPOSIT: "Supplier Deposit",
		CUSTOMER_DEPOSIT: "Customer Deposit",
		CASH_PAYMENT: "Cash Payment",
		MY_PAID: "My Paid",
		DISCOUNT: "Discount",
		BALANCE: "Balance",
		RECEIVED_DATE: "收货日期",
		ORDER_AMOUNT: "Order Amount",
		CTN: "Ctn",
		BENE_NAME: "Bank Account Name",
		BENE_CONTACT: "Bene Contact No.",
		OFFICE_CONTACT: "Office Contact No.",
		BANK_NAME: "Bank Name",
		BANK_PIC_NAME: "Bank PIC",
		BANK_CONTACT: "Bank Contact No.",
		BENE_ADDRESS: "Bene Address",
		BANK_ADDRESS: "Bank Address",
		BANK_PROVINCE: "Bank Province",
		BANK_DISTRICT: "Bank District",
		SUPPLIER_PIC: "Supplier PIC",
		SUPPLIER_NAME: "Supplier Name",
		BENE_PIC: "Bene PIC",
		BENE_DETAIL: "Beneficiary Details",
		BANK_ACCOUNT: "Bank Account",
		PRINT_AGAIN: "Reprint",
		WAREHOUSE_CHARGES: "Sticker Fees",
		WAREHOUSE_CHARGES_PARTY: "Pay by Supplier?",
		WAREHOUSE_CHARGES_PAID_AMOUNT: "Paid Amount",
		WAREHOUSE_DISCOUNT: "Warehouse Discount",
		FCP: "FCP",
		DCP: "DCP",
		MISC_AMOUNT: "Customer total paid amount",
		PAYABLE_AMT: "Payable",
		DIMENSION: "M3",
		SAVE_BENE_DETAILS: "Save as new beneficiary info",
		BENE_MODAL_TITLE: "Bene Listing",
		PN_NO_GRN_NO: "Payable Notes / GRN No.",
		MSG: {
			CTN_COUNT_NOT_SET: "Carton count not found, please check in status inquiry",
			STICKER_FEE_NOT_SET: "Sticker fee not found, please check ctn count and sticker fee",
			BENE_BANK_NOT_FOUND: "No bank records found for supplier : {supplier}",
			DOCUMENT_NO_NOT_SET: "Payables Document Number/ GRN Receipt No for selected warehouse is not set",
			PAYABLE_NOTES_EMPTY: ". Payable Notes content is empty.",
			AMOUNT_CANNOT_NEGATIVE: "Payable amount cannot negative.",
			HAVE_CLOSED_TT: "*Closed TT"
		}
	},
	PAYABLES_PAYMENT: {
		LISTING_TITLE: "欠单付款列表",
		ORDER_MODAL_TITLE: "接收订单未完成付款清单",
		MODULE_NAME: "欠单付款",
		CREATE_PAYMENT: "新建欠单付款",
		EDIT_PAYMENT: "编辑欠单付款",
		PAYMENT_DETAILS: "欠单付款详情",
		UNPAID_PAYABLES: "欠单未付款列表",
		REF_NO: "单号",
		PAYABLE_BADGE_NO: "Badge No.",
		WAREHOUSE_COUNTRY: "仓库所在国家",
		REF_NUMBER: "Ref No",
		DUE_DAYS: "截止日期",
		LOCAL_PAYABLE_AMOUNT: "Local Payable ",
		PAID_AMOUNT: "Paid",
		LOCAL_PAID_AMOUNT: "Local Paid",
		OUTSTANDING_AMOUNT: "Outstanding",
		LOCAL_OUTSTANDING_AMOUNT: "Local Outstanding",
		PAY_AMOUNT_LOCAL: "Local Pay",
		BENE_ACCOUNT_HOLDER: "Acc Holder",
		BENE_ACCOUNT_NO: "Acc No",
		BENE_CONTACT_NO: "Contact",
		BENE_CONTACT_PIC: "PIC",
		RI_NO: "RI No.",
		SWIFT_CODE: "Swift code",
		BANK_NAME: "Bank Name",
		BANK_ADDRESS: "Bank Address",
		BENE_ADDRESS: "Bene Address",
		BANK_REMARK: "银行备注",
		DATE: "付款日期",
		DEPOSIT_STATUS: "状态",
		ACTIVE_RATE_NOT_FOUND: "No active currency rate found. Please contact admin.",
		BENE_VISIBLE: "Show Bene Details",
		WAREHOUSE: "Warehouse",
		AGENT: "Agent",
		BLOCK_AMOUNT: "Blocked/Credit Note Issue amount",
		SHOW_PAYABLE_DETAILS: "Show Payable Notes Detail",
		TABLE: {
			SUPPLIER_DEPOSIT: "S/O厂家预付金额",
			CUSTOMER_DEPOSIT: "S/O客户预付金额",
			CASH_PAYMENT: "现金支付",
			WAREHOUSE_DISCOUNT: "厂家给仓库折扣",
			DISCOUNT: "折扣金额",
			MY_PAID: "支付金额 (MY)",
			STICKER_FEES: "贴纸费用",
			STICKER_FEES_PAID_AMOUNT: "厂家支付金额",
			PAYABLE_AMOUNT: "欠单金额",
			PAID_AMOUNT: "已付欠单金额",
			PN_CANCEL_AMOUNT: "欠单已取消金额",
			BALANCE_PN_AMOUNT: "欠单余额",
			PN_PAYMENT_AMOUNT: "欠单付款金额",
			LOCAL_PN_PAYMENT_AMOUNT: "Local PN Payment Amt",
			PAYABLE_BADGE_NO: "Badge Number",
			PAYMENT_DATE: "付款日期",
			DEPOSIT_STATUS: "Deposit Status",
			CURRENCY: "Currency",
			ORDER_RECEIVED_AMOUNT: "Order Receiving Amt",
			SUPPLIER_NAME: "Supplier Name",
			SUPPLIER_CONTACT: "Supplier Contact",
			DEPOSIT_AMOUNT: "预付金额",
			BANK_ACC_NAME: "Bank Holder",
			BANK_ACC: "Bank Account",
			BANK_NAME: "Bank Name",
			BANK_PROVINCE: "Bank Province",
			BANK_DISTRICT: "Bank District",
			BANK_ADDRESS: "Bank Address"
		},
		VALIDATION: {
			PAY_AMOUNT: "Payment Amt",
			LOCAL_PAY_AMOUNT: "Local Payment Amt",
			ACCOUNT_HOLDER: "Bene Account Holder",
			ACCOUNT_NO: "Bene Account Number",
			BANK_NAME: "Bank Name",
			NO_RECORD_FOUND: "No record found. Due Date : {due_date}",
			NO_ACTIVE_RATE: "每日费率未设定",
			PAYMENT_AMOUNT_CANNOT_ZERO: "Payment Amount must be larger than zero."
		}
	},
	SYSTEM_LOG: {
		BACKEND_API_LOG_TITLE: "Backend Api Log",
		GIM_API_LOG_TITLE: "Gim Api Log",
		SYNC_GIM_LOG_TITLE: "Sync To Gim Log",
		LOG_TITLE: "Log Result",

		LBL_LOG_DATE: "Log Date",
		LBL_LOG_DATE_FROM: "日期从",
		LBL_LOG_DATE_TO: "日期至",
		LBL_ERROR_CODE: "Error Code",
		COL_LOG_DATE: "Log Date",
		COL_REQUEST_PATH: "Path",
		COL_REQUEST_METHOD: "Method",
		COL_MSG: "Message",
		COL_RESULT: "Result",
		COL_ERROR_CODE: "Error Code"
	},
	PRINT: {
		PACKING_LIST: {
			LISTING_TITLE: "Packing List",
			PRINT_LISTING_TITLE: "打印 Packing List",
			REPORT_TYPE: "Report Type",
			REPORT_FILTER: "Report Filter",
			REPORT_OPTIONS: "Report Options",
			SELECT_REF_ID: "Select Ref Id",
			MSG: {
				NO_RECORD: "No Record Found."
			}
		},
		START_STOCK_BALANCE: {
			LISTING_TITLE: "Stock Balance Report with Payment Info",
			PRINT_LISTING_TITLE: "打印 Stock Balance Report with Payment Info",
			OR_DATE: "O/R Date",
			LOADED_DATE: "Loaded As At",
			REPORT_TYPE: "Report Type",
			REPORT_FILTER: "Report Filter",
			REPORT_OPTIONS: "Report Options",
			MSG: {
				NO_RECORD: "No Record Found."
			}
		}
	},
	TT: {
		MODULE_NAME: "TT Request",
		BUTTON: {
			VERIFY: "Verify",
			APPROVE: "Approve",
			ACKNOWLEDGE: "Acknowledge",
			ASSSIGN: "Assign",
			REJECT_VERIFY: "Reject Verification",
			REJECT_APPROVE: "Reject Approval",
			REJECT_ACKNOWLEDGE: "Reject Acknowledgement",
			REJECT_ASSSIGN: "Reject Assignment",
			REQUEST_AGAIN: "Request Again"
		},
		DEPOSIT_STATUS: {
			SAVE_AS_DRAFT: "Save as draft",
			PENDING_VERIFY: "Pending Verify"
		},
		PAGE: {
			REQUEST_INDEX_TITLE: "Request Listing",
			REQUEST_CREATE_TITLE: "Create New Request",
			VERIFY_TITLE: "Verify Listing",
			ACKNOWLEDGE_TITLE: "Acknowledge Listing",
			APPROVE_TITLE: "Approve Listing",
			VIEW_REQUEST_TITLE: "View Request",
			EDIT_REQUEST_TITLE: "Edit Request",
			VIEW_VERIFY_TITLE: "Verify Request",
			EDIT_VERIFY_TITLE: "Verify Request",
			VIEW_APPROVE_TITLE: "Approve Request",
			EDIT_APPROVE_TITLE: "Approve Request",
			VIEW_ACKNOWLEDGE_TITLE: "Acknowledge Request",
			EDIT_ACKNOWLEDGE_TITLE: "Acknowledge Request",
			BENE_DETAIL_TITLE: "Bene Details"
		},
		INDEX: {
			DEPOSIT_STATUS: "Deposit Status",
			REF_NO: "Reference No.",
			TT_DATE: "TT Date",
			MAIN_SALESMAN: "Main Salesman",
			SALESMAN: "Salesman",
			MARKING: "客户代号",
			COMPANY: "Company",
			TOTAL_TT_AMOUNT: "Total TT Amount",
			TOTAL_LOCAL_TT_AMOUNT: "Total Local TT Amount",
			TT_CHARGES: "TT Charges",
			TT_CATEGORY: "TT Category",
			ACKNOWLEDGE_DATE: "Acknowledged Date",
			CURRENCY: "Currency",
			WAREHOUSE_COUNTRY: "WH Country",
			REJECT_REASON: "Reject Reason"
		},
		HEADER_MODEL: {
			REF_NO: "Reference Number",
			WAREHOUSE: "Warehouse",
			MARKING: "客户代号",
			CURRENCY: "TT Currency",
			RATE: "Rate",
			ACCOUNT_TYPE: "Account Type",
			BENE_TYPE: "Bene Type",
			TT_CATEGORY: "TT Category",
			CUSTOMER_REMARK: "Customer Remark",
			AR_REMARK: "Admin Remark",
			OTHER_AMT: "Other Amount",
			OTHER_LOCAL_AMT: "Other Local Amount",
			WITHOUT_OR: "Without OR?",
			OR_AMOUNT: "OR Amount",
			ACTUAL_AMOUNT: "Actual Amount",
			FINANCE_P: "F-m/up(%)",
			TT_CHARGES_CURRENCY: "TT Charges Currency",
			TT_CHARGES: "TT Charges",
			EXT_TO_LOCAL: "Ext To Local",
			TOTAL_TT_AMT: "Total TT Amount",
			TOTAL_LOC_TT_AMT: "Total Local TT Amount",
			OUTGOING_CURRENCY: "Outgoing Currency",
			CONVERTED_RATE: "Converted Rate",
			OUTGOING_TT_AMT: "Outgoing TT Amount",
			OUTGOING_LOC_TT_AMT: "Outgoing Local TT Amount",
			ADD_EPO_SO: "Add EPO / Order Received",
			EPO_CURRENCY: "Epo Currency",
			ANOTHER_TT_CURRENCY: "Another TT Currency?",
			PAYABLE_BADGE_NO: "Payable Notes Badge No",
			AP_REMARK: "AP Remark"
		},
		BENE_MODEL: {
			BENE_TITLE: "Beneficiary Details",
			ACC_HOLDER: "Account Holder",
			ACC_NO: "Account Number",
			BENE_CONTACT_PIC: "Bene Contact PIC",
			BENE_CONTACT_CALLING_CODE: "Bene Calling Code",
			BENE_CONTACT_NO: "Bene Contact Number",
			RI_NO: "Ri Number",
			BANK_NAME: "Bank Name",
			BANK_CITY: "Bank City",
			BANK_COUNTRY: "Bank Country",
			BANK_ADDRESS: "Bank Address",
			BENE_ADDRESS: "Bene Address",
			BANK_REMARK: "Bank Remark",
			BANK_CODE: "BIC / Swift Code",
			IBAN: "IBAN"
		},
		DETAIL_MODEL: {
			EPO_NO: "EPO/SO",
			EPO_AMOUNT: "Epo Amount",
			PAY_AMOUNT: "Pay Amount",
			PAY_AMOUNT_LOCAL: "Local Pay Amount",
			ADJUSTMENT_INDICATOR: "+/-",
			ADJUSTMENT_AMOUNT: "Adjustment Amount",
			ADJUSTMENT_AMOUNT_LOCAL: "Local Adjustment Amount",
			REMARK: "Remark",
			OUTSTANDING_AMOUNT: "Outstanding Amount",
			CANCEL: "Cancel",
			ADD: "Add",
			SUBTOTAL: "Sub Total",
			SUBTOTAL_LOCAL: "Local Sub Total",
			AMOUNT_IN_PROCESS: "Amount In Process",
			RECEIVED_AMOUNT: "Received Amount",
			ALT_DEPOSIT: "TTed Amount ({mod})",
			ALT_APPLIED_ADJUSTMENT: "Applied TT Adjustment ({mod})",
			ALT_PAY_AMOUNT: "Pay Amount ({mod})",
			ALT_ADJUSTMENT_AMOUNT: "Adjustment Amount ({mod})",
			ALT_SUBTOTAL: "Sub Total ({mod})",
			IS_DEPOSIT: "Deposit/Payment",
			CURRENCY_CODE: "Currency Code",
			DATE: "Epo/收货日期",
			SUPPLIER_NAME: "Supplier Name",
			SHIPMENT_TYPE: "Shipment Type",
			GRAND_TOTAL: "Epo Amount/Received Amount",
			TTED_AMOUNT: "TTed Amount"
		},
		FOOTER_MODEL: {
			SUBMIT: "Submit",
			VERIFY: "Verify",
			APPROVE: "Approve",
			ACKNOWLEDGE: "Acknowledge",
			SAVE_AS_DRAFT: "Save As Draft",
			REJECT: "Reject",
			CANCEL: "Cancel"
		},
		ADD_EPO_SO: "Add EPO / Order Received (SO) ",
		VERIFY_REJECT: "Reject",
		APPROVE_REJECT: "Reject",
		ACKNOWLEDGE_REJECT: "Reject",
		VERIFY: "Verified",
		APPROVE: "Approved",
		ACKNOWLEDGE: "Acknowledged",
		DATE: "Date",
		EPO_NO: "Epo No",
		STATUS: "Status",
		MAIN_SALESMAN: "Main Salesman",
		SALESMAN: "Salesman",
		CURRENCY_CODE: "Currency Code",
		AMOUNT: "Amount",
		EPO_DATE: "Epo Date",
		CUSTOMER_NAME: "Customer Name",
		SO_NO: "SO No",
		RECEIVE_DATE: "Receive Date",
		TTED_AMOUNT: "TTed Amount",
		TTED_AMOUNT_LOCAL: "Local TTed Amount",
		SUPPLIER_NAME: "Supplier Name",
		SHIPMENT_TYPE: "Shipment Type",
		DISCOUNT_AMOUNT: "EPO/SO Discount",
		APPLIED_TT_ADJUSTMENT: "Applied TT Adjustment",
		APPLIED_TT_ADJUSTMENT_LOCAL: "Local Applied TT Adjustment",
		FINAL_TT_AMOUNT: "Final TT Amount",
		FINAL_TT_AMOUNT_LOCAL: "Final Local TT Amount",
		CONFIRM: "Confirm",
		REJECT: "Reject",
		SO_MODULE: "Order Received",
		MSG_RATE_EXPIRED: "Currency rate effective time expired, TT request is prohibited",
		MSG_TT_CHARGES_NOT_FOUND: "Bank city is not set, tt charges is empty.",
		PAY_AMOUNT_CANNOT_EXCEED: "Pay amount cannot exceed outstanding amount",
		REJECT_REASON: {
			SELECT_REASON: "Reject reason",
			OTHER: "Other reason",
			PH_OTHER: "Please input other reason"
		}
	},
	GIMWORLD_ORDER: {
		MAINTENANCE: {
			MENT_NAME: "Gimworld Order Maintenance",
			LISTING_TITLE: "Gimworld Order Maintenance",
			DATE_FROM: "输入 日期从",
			DATE_TO: "输入 日期至",
			CREATION_DATE: "Creation Date",
			EDIT_SUCCESS: "Successfully modified",
			CREATION_DATE_FROM: "Creation 日期从",
			CREATION_DATE_TO: "Creation 日期至",
			IMPORT: "Import GimWorld Excel File",
			BATCH: "Batch ID",
			FILE: "File",
			ONLY_EXCEL_FILES_CAN_BE_UPLOADED: "Only excel files can be uploaded!",
			SHOW_POSTED: "Show Posted?",
			IMPORT_COMPLETED: "Import Completed!",
			POSTED: "Posted?",
			STOCK_MASTER_ID: "Stock Master ID",
			DATE: "Date",
			MARKING: "客户代号",
			DELIVERY_ID: "Delivery ID",
			TRACKING_NO: "Tracking No",
			DESC: "Desc 1",
			DESC2: "Desc 2",
			QTY: "QTY",
			AMOUNT: "Amount",
			CTN: "Ctn",
			WEIGHT: "Weight",
			M3: "M3",
			DIMENSION: "Dimension",
			UOM: "UOM",
			SHIPMENT_TYPE: "Shipment type",
			COUNTRY: "Country",
			STATE: "State",
			CITY: "City",
			CONSIGNEE: "Consignee",
			TEL_NO: "Tel no",
			POST_CODE: "Post Code",
			ADDRESS: "Address",
			REF_NO: "Ref No",
			REMARK: "Remark",
			WAREHOUSE: "Warehouse"
		},
		POST_ORDER: {
			MENT_NAME: "Post to Order Receiving",
			LISTING_TITLE: "Post to Order Receiving",
			DATE_FROM: "输入 日期从",
			DATE_TO: "输入 日期至",
			CREATION_DATE: "Creation Date",
			EDIT_SUCCESS: "Successfully modified",
			SUCCESSFULLY_POST_TO_ORDER_RECEIVING: "Successfully post to Order Receiving!",
			CREATION_DATE_FROM: "Creation 日期从",
			CREATION_DATE_TO: "Creation 日期至",
			POST: "Post",
			BATCH: "Batch ID",
			SHOW_POSTED: "Show Posted?",
			IMPORT_COMPLETED: "Import Completed!",
			POSTED: "Posted?",
			STOCK_MASTER_ID: "Stock Master ID",
			DATE: "Date",
			MARKING: "客户代号",
			DELIVERY_ID: "Delivery ID",
			TRACKING_NO: "Tracking No",
			DESC: "Desc 1",
			DESC2: "Desc 2",
			QTY: "QTY",
			AMOUNT: "Amount",
			CTN: "Ctn",
			WEIGHT: "Weight",
			M3: "M3",
			DIMENSION: "Dimension",
			UOM: "UOM",
			SHIPMENT_TYPE: "Shipment type",
			COUNTRY: "Country",
			STATE: "State",
			CITY: "City",
			CONSIGNEE: "Consignee",
			TEL_NO: "Tel no",
			POST_CODE: "Post Code",
			ADDRESS: "Address",
			REF_NO: "Ref No",
			REMARK: "Remark",
			WAREHOUSE: "Warehouse",
			MSG: {
				MSM_CODE_NOT_EXISTS: "Main Salesman: {msm_code} not exists.",
				CUSTOMER_MARKING_NOT_FOUND: "客户代号: {msm_code}/{sm_code}/{marking} not exists.",
				WAREHOUSE_COMPANY_GROUP_NOT_SET: "Warehouse: {warehouse_code}'s company group not set.",
				DELIVERY_ADDRESS_NOT_FOUND: "Delivery ID: {delivery_id} not exists.",
				SHIPMENT_TYPE_NOT_FOUND: "Shipment Type: {shipment_type} not exists",
				UOM_CODE_NOT_FOUND: "Uom: {uom_code} not exists"
			}
		}
	}
};
