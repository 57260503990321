import i18n from "@/core/plugins/vue-i18n";
import Common from "@/core/services/common";
import ApiService from "@/core/services/api.service";

const getAgent = async function (idx_page = 0) {
	let response = await ApiService.post("agent/list");
	return response.data.code == 200 ? response.data.result : [];
};

const getWarehouse = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("warehouse/selection", {})
		.then((response) => {
			if (response.status == 200) {
				let warehouse = default_val.concat(response.data.result);
				return warehouse;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};

const getWarehouseLocation = function (warehouse_id, idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("warehouse-location/selection", { warehouse_id: warehouse_id })
		.then((response) => {
			if (response.status == 200) {
				let WarehouseLocation = default_val.concat(response.data.result);
				return WarehouseLocation;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};

const getChecker = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("user/checker")
		.then((response) => {
			if (response.status == 200) {
				let checker = default_val.concat(response.data.result);
				return checker;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};

const getSupplier = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("supplier/list", {})
		.then((response) => {
			if (response.status == 200) {
				let supplier = default_val.concat(response.data.result);
				return supplier;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};

const getMarking = function (agent_id, idx_page = 0, customer_id = null) {
	let default_val = idx_page == 1 ? [] : [];
	return ApiService.post("customer-marking/selection", {
		agent_id: typeof agent_id == "object" ? null : agent_id,
		agent_ids: typeof agent_id == "object" ? agent_id : null,
		customer_id: typeof customer_id == "object" ? null : customer_id,
		customer_ids: typeof customer_id == "object" ? customer_id : null
	})
		.then((response) => {
			if (response.status == 200) {
				let marking = default_val.concat(response.data.result);
				return marking;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};

const getNoticeType = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("notice-type/selection")
		.then((response) => {
			if (response.status == 200) {
				let noticeType = default_val.concat(response.data.result);
				return noticeType;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};

const getCategory = function (parentId = 0, idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.get("category/selection", parentId)
		.then((response) => {
			if (response.status == 200) {
				let Category = default_val.concat(response.data.result);
				return Category;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getSecondLevelCategoryAll = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.get("category/second-level-all")
		.then((response) => {
			if (response.status == 200) {
				let Category = default_val.concat(response.data.result);
				return Category;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getCategoryInvoice = function (parentId = 0, idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.get("category/invoice/selection", parentId)
		.then((response) => {
			if (response.status == 200) {
				let CategoryInvoice = default_val.concat(response.data.result);
				return CategoryInvoice;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getUom = function (idx_page = 0, data = { use_epo_inv_receive: 1 }) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("uom/selection", data)
		.then((response) => {
			if (response.status == 200) {
				let uom = default_val.concat(response.data.result);
				return uom;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getRefIdFormat = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.get("doc-num/ref-id-format")
		.then((response) => {
			if (response.status == 200) {
				let data = default_val.concat(response.data.result);
				return data;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getShipmentType = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("shipment-type/selection", {})
		.then((response) => {
			if (response.status == 200) {
				let data = default_val.concat(response.data.result);
				return data;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};

const getShipmentTerms = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.get("shipment-terms/selection")
		.then((response) => {
			if (response.status == 200) {
				let data = default_val.concat(response.data.result);
				return data;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getForwarder = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.get("forwarder/list")
		.then((response) => {
			if (response.status == 200) {
				let data = default_val.concat(response.data.result);
				return data;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getPort = function (country_id, idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("port/selection", { country_id: country_id })
		.then((response) => {
			if (response.status == 200) {
				let data = default_val.concat(response.data.result);
				return data;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getRefType = function (shipment_type_id, idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("ref-type/selection", { shipment_type_id: shipment_type_id })
		.then((response) => {
			if (response.status == 200) {
				let data = default_val.concat(response.data.result);
				return data;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getShipper = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("shipper/selection", {})
		.then((response) => {
			if (response.status == 200) {
				let data = default_val.concat(response.data.result);
				return data;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getShippingLine = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("shipping-line/selection", {})
		.then((response) => {
			if (response.status == 200) {
				let data = default_val.concat(response.data.result);
				return data;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getForwarderGroup = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("forwarder-group/selection", {})
		.then((response) => {
			if (response.status == 200) {
				let data = default_val.concat(response.data.result);
				return data;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};
const getConsignee = function (idx_page = 0) {
	let default_val = idx_page == 1 ? Common.selection_all : [];
	return ApiService.post("consignee/selection", {})
		.then((response) => {
			if (response.status == 200) {
				let data = default_val.concat(response.data.result);
				return data;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};

const getCustomerSelection = function (agent_id, idx_page = 0) {
	let default_val = idx_page == 1 ? [] : [];
	return ApiService.post("customer/selection", {
		agent_id: typeof agent_id == "object" ? null : agent_id,
		agent_ids: typeof agent_id == "object" ? agent_id : null
	})
		.then((response) => {
			if (response.data.code == 200) {
				let result = default_val.concat(response.data.result);
				return result;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};

const getMsmSelection = function (agent_id, idx_page = 0) {
	let default_val = idx_page == 1 ? [] : [];
	return ApiService.post("main-salesman/selection", {
		agent_id: typeof agent_id == "object" ? null : agent_id,
		agent_ids: typeof agent_id == "object" ? agent_id : null
	})
		.then((response) => {
			if (response.data.code == 200) {
				let result = default_val.concat(response.data.result);
				return result;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};

const getSmSelection = function (agent_id, msm_id = null, idx_page = 0) {
	let default_val = idx_page == 1 ? [] : [];
	return ApiService.post("salesman/selection", {
		agent_id: typeof agent_id == "object" ? null : agent_id,
		agent_ids: typeof agent_id == "object" ? agent_id : null,
		msm_id: typeof msm_id == "object" ? null : msm_id,
		msm_ids: typeof msm_id == "object" ? msm_id : null
	})
		.then((response) => {
			if (response.data.code == 200) {
				let result = default_val.concat(response.data.result);
				return result;
			} else {
				return default_val;
			}
		})
		.catch(() => {
			return default_val;
		});
};

const companyGroupSelection = async function (post_data = {}) {
	let response = await ApiService.post("company/group-selection", post_data);
	return response.data.code == 200 ? response.data.result : [];
};

const companySelection = async function (post_data = {}) {
	let response = await ApiService.post("company/selection", post_data);
	return response.data.code == 200 ? response.data.result : [];
};

const getDeliveryAddressSelection = async function (post_data = {}) {
	let response = await ApiService.post("delivery-address/selection", post_data);
	return response.data.code == 200 ? response.data.result : [];
};

const markingStatusSelection = [
	{
		id: 0,
		name: i18n.t("COMMON.STATUS.INACTIVE"),
		color: "dange"
	},
	{
		id: 1,
		name: i18n.t("COMMON.STATUS.ACTIVE"),
		color: "success"
	},
	{
		id: 3,
		name: i18n.t("COMMON.STATUS.SUSPEND"),
		color: "warning"
	}
];

const statusSelection = [
	{
		item: null,
		name: i18n.t("COMMON.TXT_ALL")
	},
	{
		item: 1,
		name: i18n.t("COMMON.STATUS.ACTIVE")
	},
	{
		item: 0,
		name: i18n.t("COMMON.STATUS.INACTIVE")
	}
];

export default {
	getAgent,
	getWarehouse,
	getWarehouseLocation,
	getChecker,
	getSupplier,
	getMarking,
	getNoticeType,
	getCategory,
	getCategoryInvoice,
	getUom,
	getRefIdFormat,
	getShipmentType,
	getShipmentTerms,
	getForwarder,
	getPort,
	getRefType,
	getShipper,
	getShippingLine,
	getForwarderGroup,
	getConsignee,
	getCustomerSelection,
	getMsmSelection,
	getSmSelection,
	getSecondLevelCategoryAll,
	companyGroupSelection,
	companySelection,
	markingStatusSelection,
	statusSelection,
	getDeliveryAddressSelection
};
