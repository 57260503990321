import ApiService from "@/core/services/api.service";
import i18n from "@/core/plugins/vue-i18n";

const warehouseSelection = async function (post_data = {}) {
	let response = await ApiService.post("warehouse/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const warehouseCountrySelection = async function (marking_id = null) {
	let response = await ApiService.post("warehouse/country-selection", {
		locale: i18n.locale,
		marking_id: marking_id,
		country_id: null,
		admin_portal: 1
	});

	return response.data.code == "200" ? response.data.result : [];
};

const warehouseSettingsSelection = async function (post_data = {}) {
	let response = await ApiService.post("warehouse-settings/search", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const customerSelection = async function (post_data = {}) {
	let response = await ApiService.post(`customer/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const mainSalesmanSelection = async function (post_data = {}) {
	let response = await ApiService.post(`main-salesman/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const salesmanSelection = async function (post_data = {}) {
	let response = await ApiService.post(`salesman/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const deliveryZoneSelection = async function () {
	let response = await ApiService.get(`delivery-zone/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const deliveryAddressSelection = async function (post_data = {}) {
	/*
	 * Long 		marking_id
	 * Long			agent_id
	 * Long[]		customer_ids
	 */

	let response = await ApiService.post(`delivery-address/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const companyGroupSelection = async function (post_data = {}) {
	let response = await ApiService.post(`company/group-selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const categorySelection = async function (post_data = {}) {
	let response = await ApiService.post(`category/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const getCourierCompanySelection = async function (post_data = {}) {
	let response = await ApiService.post(`courier-company/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const getMaintainAgentSelection = async function () {
	let response = await ApiService.post(`maintain-agent/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const getAgentCourierSelection = async function (post_data = {}) {
	let response = await ApiService.post(`maintain-agent/agent_courier/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const categoryLvl2Selection = async function (post_data = {}) {
	let response = await ApiService.post(`category/child-selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const taxSelection = async function () {
	let response = await ApiService.get(`tax/selection-all`);
	return response.data.code == "200" ? response.data.result : [];
};

const supplierSelection = async function (post_data = {}) {
	let response = await ApiService.post(`supplier/list`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const markingSelection = async function (post_data = {}) {
	let response = await ApiService.post("customer-marking/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const statusSelection = [
	{
		item: null,
		name: i18n.t("COMMON.TXT_ALL")
	},
	{
		item: 1,
		name: i18n.t("COMMON.STATUS.ACTIVE")
	},
	{
		item: 0,
		name: i18n.t("COMMON.STATUS.INACTIVE")
	}
];

const yesNoSelection = function (include_all = 0) {
	let selection = [];
	if (include_all == 1) {
		selection.push({
			id: null,
			name: i18n.t("COMMON.TXT_ALL")
		});
	}
	return selection.concat([
		{
			id: 1,
			name: i18n.t("COMMON.STATUS.YES")
		},
		{
			id: 0,
			name: i18n.t("COMMON.STATUS.NO")
		}
	]);
};

const uploadFile = async function (data) {
	let response = await ApiService.uploadfile("upload/image", data, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});

	if (response.status == "200" && response.data.code == "200") {
		if (response.data.result.fileDownloadUri != null || response.data.result.fileDownloadUri != undefined) {
			return response.data.result.fileDownloadUri;
		} else {
			return null;
		}
	} else {
		return null;
	}
};
const shipmentTypeSelection = async function () {
	var response = await ApiService.get("shipment-type/list");
	return response.data.code == "200" ? response.data.result : [];
};

const shipmentModeSelection = async function (post_data = {}) {
	let response = await ApiService.post("shipment-mode/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const countrySelection = async function () {
	let response = await ApiService.get("countries/active");
	return response.data.code == "200" ? response.data.result : [];
};

const refIdSelection = async function (post_data = {}) {
	let response = await ApiService.post("container-loading/ref-ids", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const noticeTypeSelection = async function (post_data = {}) {
	let response = await ApiService.post("notice-type/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const currencySelection = async function (post_data = {}) {
	let response = await ApiService.post("currency/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const userTypeSelection = [
	{
		id: 1,
		name: i18n.t("USER_N_ROLE_MANAGEMENT.USER.USER_TYPE.WAREHOUSE_STAFF")
	},
	{
		id: 2,
		name: i18n.t("USER_N_ROLE_MANAGEMENT.USER.USER_TYPE.AGENT_STAFF")
	}
];

const checkerSelection = async function (post_data) {
	let response = await ApiService.post("user/selection", post_data)
	return response.data.code == "200" ? response.data.result : [];
}

export default {
	warehouseSelection,
	warehouseCountrySelection,
	warehouseSettingsSelection,
	customerSelection,
	mainSalesmanSelection,
	salesmanSelection,
	deliveryZoneSelection,
	deliveryAddressSelection,
	companyGroupSelection,
	categorySelection,
	categoryLvl2Selection,
	taxSelection,
	supplierSelection,
	uploadFile,
	statusSelection,
	getCourierCompanySelection,
	getMaintainAgentSelection,
	getAgentCourierSelection,
	shipmentTypeSelection,
	countrySelection,
	markingSelection,
	refIdSelection,
	yesNoSelection,
	noticeTypeSelection,
	currencySelection,
	userTypeSelection,
	checkerSelection,
	shipmentModeSelection
};
